import React from "react"
import { Global as GlobalCss, css } from "@emotion/core"
import theme from "../gatsby-plugin-theme-ui"

const Global = () => {
  const breakpoints = [640, 768, 1024, 1280]

  const mq = breakpoints.map(bp => `@media (min-width: ${bp}px)`)

  return (
    <GlobalCss
      styles={css`
        html {
          box-sizing: border-box;
          margin: 0;
          border: 0;
        }
        *,
        *:before,
        *:after {
          box-sizing: inherit;
        }
        a {
          color: ${theme.colors.children[2]};
        }
        p,
        li {
          font-family: ${theme.fonts.body};
          font-weight: normal;
        }
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-family: ${theme.fonts.heading};
          line-height: ${theme.lineHeights.snug};
        }
        h1 {
          font-size: ${theme.fontSizes[5]};
          font-weight: normal;
          margin: 0;
          ${mq[0]} {
            font-size: ${theme.fontSizes[5]};
          }
          ${mq[1]} {
            font-size: ${theme.fontSizes[6]};
          }
          ${mq[2]} {
            font-size: ${theme.fontSizes[6]};
          }
        }
        h2 {
          color: ${theme.colors.children[2]};
          font-size: ${theme.fontSizes[4]};
          font-weight: normal;
          margin: 0;
          margin-top: ${theme.space[4]};
        }
        h3 {
          font-size: ${theme.fontSizes[3]};
          font-weight: bold;
          margin: 0;
          ${mq[0]} {
            font-size: ${theme.fontSizes[3]};
          }
          ${mq[1]} {
            font-size: ${theme.fontSizes[3]};
          }
        }
        h4 {
          font-size: ${theme.fontSizes[1]};
          font-weight: normal;
          margin: 0;
          ${mq[1]} {
            font-size: ${theme.fontSizes[2]};
          }
        }
        h5 {
          font-size: ${theme.fontSizes[1]};
          font-weight: normal;
          margin: 0;
          ${mq[1]} {
            font-size: ${theme.fontSizes[2]};
          }
        }
        li p {
          margin: 0;
        }

        .text-lg {
          font-size: ${theme.fontSizes[2]};
        }
        .text-sm {
          font-size: ${theme.fontSizes[0]};
        }

        [data-reach-skip-link] {
          background: white;
          left: -99999em;
          padding: 0.5em;
          position: absolute;
          z-index: 10;
        }
        [data-reach-skip-link]:focus {
          left: 0.5em;
          top: 0.5em;
        }
        .breadcrumb .breadcrumb__list {
          list-style: none;
          margin: 0;
          padding: 0;
          position: absolute;
          bottom: 1em;
          ${mq[0]} {
            bottom: 1.5em;
          }
        }
        .breadcrumb__list__item {
          display: inline-block;
        }
        .breadcrumb__link {
          color: white;
          font-size: 12px;
          text-decoration: none;
          text-transform: uppercase;
          &:hover {
            text-decoration: underline;
          }
        }
        /* Post-Blog Styles */

        .post .breadcrumb .breadcrumb__list {
          position: relative
        }

        .post img{
          width: 100%;
          aspect-ratio: 4/3;
          object-fit: cover;
        }

        /* IE10+ CSS styles go here */
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          #colorbar {
            display: none;
          }
          .header-nav {
            padding-bottom: 0;
            padding-top: 0;
            margin: 0;
          }
        }

        /* Dato Search Styles */

        .datocms-widget {
          margin: 0;
          padding: 3em 1em;
          height: 2500px;
          width: 100%;
          ${mq[0]} {
            padding: 3em 4em;
          }
          ${mq[1]} {
            padding: 3em 8em;
          }
        }

        .datocms-widget__result {
          margin-bottom: 15px;
          border-left: 3px solid transparent;
          padding-left: 15px;
          border-left-color: ${theme.colors.gray[3]};
          ${mq[1]} {
            padding-right: 18px;
          }
        }

        .datocms-widget__result:hover {
          border-left-color: ${theme.colors.children[2]};
        }

        .datocms-widget__result__title {
          color: ${theme.colors.children[2]};
          display: block;
          font-size: ${theme.fontSizes[1]};
          text-decoration: underscore;
          margin-bottom: 3px;
        }

        .datocms-widget__result__body {
          margin-bottom: 10px;
          font-size: ${theme.fontSizes[1]};
          margin-bottom: 3px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .datocms-widget__result__url {
          margin-bottom: 10px;
          font-size: ${theme.fontSizes[0]};
          color: ${theme.colors.gray[6]};
        }

        .datocms-widget__loading__inner-1,
        .datocms-widget__loading__inner-2 {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          background-color: rgba(0, 0, 0, 0.2);
          opacity: 0.6;
          position: absolute;
          top: 0;
          left: 0;
          -webkit-animation: datocms-widget-bounce 2s infinite ease-in-out;
          animation: datocms-widget-bounce 2s infinite ease-in-out;
        }

        .datocms-widget__loading__inner-2 {
          -webkit-animation-delay: -1s;
          animation-delay: -1s;
        }

        .datocms-widget__body {
          position: relative;
        }

        @media screen and (min-width: 600px) {
          .datocms-widget__body {
            min-height: 80px;
            max-height: 60vh;
            overflow: auto;
          }
        }

        .datocms-widget__loading {
          position: absolute;
          top: 50%;
          left: 50%;
          margin-top: -20px;
          margin-left: -20px;
          width: 40px;
          height: 40px;
        }

        .datocms-widget__no-results__label {
          text-align: center;
          padding: 40px;
          font-size: ${theme.fontSizes[3]};
          color: ${theme.colors.gray[6]};
        }

        .datocms-widget__header {
          margin-bottom: 20px;
          padding-bottom: 20px;
          border-bottom: 1px solid ${theme.colors.gray[3]};
        }

        .datocms-widget__search__input {
          width: 100%;
          box-sizing: border-box;
          border: 0;
          padding: 0;
          font-size: ${theme.fontSizes[3]};
          padding: 18px;
        }

        .datocms-widget__search__input:focus {
          outline: none;
        }

        @media screen and (max-width: 599px) {
          .datocms-widget__search__input {
            margin-bottom: 20px;
          }

          .datocms-widget__search__input {
            box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
          }
        }

        @media screen and (min-width: 600px) {
          .datocms-widget__header__inner {
            box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
            display: flex;
            align-items: center;
          }

          .datocms-widget__search {
            flex: 1;
          }
        }

        .datocms-widget__footer {
          margin-top: 15px;
          padding-top: 15px;
          border-top: 1px solid ${theme.colors.gray[3]};
          font-size: ${theme.fontSizes[1]};
          color: #666;
          display: flex;
          align-items: center;
        }

        .datocms-widget__total {
          text-align: right;
          padding: 5px;
        }

        .datocms-widget__pagination {
          flex: 1;
        }

        .datocms-widget__pagination__page {
          display: inline-block;
          padding: 5px;
          margin-bottom: 3px;
          color: ${theme.colors.children[2]};
        }

        .datocms-widget__pagination__page:hover {
          text-decoration: underscore;
        }

        .datocms-widget__pagination__page.is-active {
          text-decoration: none;
          color: ${theme.colors.gray[6]};
        }

        @keyframes datocms-widget-bounce {
          0%,
          100% {
            transform: scale(0);
            -webkit-transform: scale(0);
          }
          50% {
            transform: scale(1);
            -webkit-transform: scale(1);
          }
        }
      `}
    />
  )
}

export default Global
