/** @jsx jsx */
import { jsx, Box } from "theme-ui"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import { ThemeProvider } from "emotion-theming"
import { SkipNavLink, SkipNavContent } from "@reach/skip-nav"
import { Helmet } from "react-helmet"

import theme from "@giraldomac/gatsby-theme-wvumedicine/src/gatsby-plugin-theme-ui"
import Global from "@giraldomac/gatsby-theme-wvumedicine/src/utils/global"
import {
  Footer,
  Header,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      {
        site {
          siteMetadata {
            siteTitle
            facebook
            instagram
            twitter
            youTube
            phone
            address
            email
          }
        }
      }
    `}
    render={data => (
      <ThemeProvider theme={theme}>
        <Helmet>
          <html lang="en" />
        </Helmet>
        <Global />

        <SkipNavLink />

        <Header />

        <SkipNavContent />
        <Box
          sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
        >
          {children}
          <Footer
            siteName={data.site.siteMetadata.siteTitle}
            facebook={data.site.siteMetadata.facebook}
            instagram={data.site.siteMetadata.instagram}
            twitter={data.site.siteMetadata.twitter}
            youTube={data.site.siteMetadata.youTube}
            phone={data.site.siteMetadata.phone}
            address={data.site.siteMetadata.address}
            email={data.site.siteMetadata.email}
          />
        </Box>
      </ThemeProvider>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
