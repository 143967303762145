/** @jsx jsx */
import { jsx, Box, Container, Flex, Grid } from "theme-ui"

import { Section } from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout"
import { SectionHeading } from "../elements"

function QuickLinks(props) {
  return (
    <Section id="quicklinks" sx={{ backgroundColor: "babyblue" }}>
      <Container sx={{ px: "1em" }}>
        <Flex sx={{ flexWrap: "wrap" }}>
          <Box sx={{ color: "text", textAlign: "center", width: ["full"] }}>
            <SectionHeading>{props.title || "Quick Links"}</SectionHeading>
          </Box>
          <Box sx={{ width: ["full"] }}>
            <Grid gap={3} columns={[1, 2, 3]} sx={{ gridAutoRows: "1fr" }}>
              {props.children}
            </Grid>
          </Box>
        </Flex>
      </Container>
    </Section>
  )
}

export default QuickLinks
