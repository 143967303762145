/** @jsx jsx */
import { jsx, Box, Button, Flex, Styled } from "theme-ui"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

const DonorStory = () => {
  const data = useStaticQuery(graphql`
    {
      allDatoCmsDonorStory(filter: { meta: { status: { eq: "published" } } }) {
        edges {
          node {
            image {
              fluid(
                maxWidth: 800
                imgixParams: { fm: "jpg", auto: "compress" }
              ) {
                ...GatsbyDatoCmsFluid
              }
            }
            contentNode {
              childMarkdownRemark {
                excerpt(truncate: true, pruneLength: 140)
              }
            }
            slug
            title
            id
            meta {
              status
            }
          }
        }
      }
    }
  `)
  return (
    <Flex sx={{ flexWrap: "wrap", justifyContent: "center", mx: [0, 0, -2] }}>
      {data.allDatoCmsDonorStory.edges.map(({ node: story }) => (
        <Box
          key={story.id}
          sx={{ px: [0, 0, 2], width: ["full", "full", "1/3"] }}
        >
          <Box sx={{ bg: "white", boxShadow: "lg", my: 3 }}>
            <Link
              to={`/giving/donor-stories/${story.slug}`}
              sx={{ color: "white", textDecoration: "none" }}
            >
              <Img fluid={story.image.fluid} />
              <Box
                sx={{
                  backgroundColor: "children.1",
                  px: [4],
                  py: [3, 4],
                  textAlign: "left",
                }}
              >
                <Styled.p
                  sx={{
                    color: "white",
                    fontSize: 0,
                    fontWeight: "bold",
                    textTransform: "uppercase",
                  }}
                >
                  Donor Story
                </Styled.p>
                <Styled.h3 sx={{ color: "white", mb: 3 }}>
                  {story.title}
                </Styled.h3>

                <Link to={`/giving/donor-stories/${story.slug}`}>
                  <Button
                    as="span"
                    variant="inverted"
                    sx={{
                      "&:hover": {
                        color: "children.1",
                      },
                    }}
                  >
                    Read Donor's Story
                  </Button>
                </Link>
              </Box>
            </Link>
          </Box>
        </Box>
      ))}
    </Flex>
  )
}

export default DonorStory
