/** @jsx jsx */
import { jsx, Box } from "theme-ui"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faHeart,
  faLaptopMedical,
  faCalendarPlus,
  faUserMd,
} from "@fortawesome/pro-regular-svg-icons"

import {
  LinkExt,
  NavLink,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"

const NavSecondary = props => {
  return (
    <Box as="ul" {...props}>
      <NavLink>
        <LinkExt
          href="https://mywvuchart.com/MyChart/"
          activeClassName="active"
        >
          <FontAwesomeIcon icon={faLaptopMedical} />
          <span>MyWVUChart</span>
        </LinkExt>
      </NavLink>
      <NavLink>
        <LinkExt
          href="https://wvumedicine.org/find-a-doctor/"
          activeClassName="active"
        >
          <FontAwesomeIcon icon={faUserMd} />
          <span>Find a Doctor</span>
        </LinkExt>
      </NavLink>
      <NavLink>
        <LinkExt
          href="https://wvumedicine.org/online-appointment-primary-care-physician/"
          activeClassName="active"
        >
          <FontAwesomeIcon icon={faCalendarPlus} />
          <span>Make an Appointment</span>
        </LinkExt>
      </NavLink>
      <NavLink>
        <LinkExt
          href="https://give.wvu.edu/give/430998/#!/donation/checkout"
          activeClassName="active"
          sx={{
            backgroundColor: ["transparent", "transparent", "secondary"],
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "transparent",
            borderRadius: "sm",
            color: ["gray.1", "gray.1", "primary"],
            transition: `all 0.4s ease 0s`,
            px: [0, 0, 3],
            py: [0, 0, 2],
            svg: {
              color: "red.7",
            },

            "&:hover": {
              borderBottomColor: ["secondary", "secondary"],
              svg: {
                animation: `pulse 1s infinite`,
                color: "red.7",
                "@keyframes pulse": {
                  "0%": {
                    transform: "scale(1,1)",
                    transitionProperty: "transform",
                    transitionDuration: "0.15s",
                  },
                  "100%": {
                    transform: "scale(1.25,1.25)",
                    transitionProperty: "transform",
                    transitionDuration: "0.15s",
                  },
                },
              },
            },
          }}
        >
          <FontAwesomeIcon icon={faHeart} />
          <span
            sx={{
              color: ["black", "black", "primary"],
              ":hover": { color: ["black", "black", "primary"] },
            }}
          >
            Giving
          </span>
        </LinkExt>
      </NavLink>
    </Box>
  )
}

export default NavSecondary
