/** @jsx jsx */
import { jsx, Box, Button, Flex, Styled, AspectRatio } from "theme-ui"
import { Link } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

const ClinicCard = ({ clinic, ...props }) => {
  return (
    <Box sx={{ px: [0, 0, 2], my: [2], width: props.width }}>
      <Link
        to={`/outpatient-clinics/${clinic.slug}`}
        sx={{ textDecoration: "none" }}
      >
        <Flex
          sx={{
            bg: "white",
            borderRadius: "default",
            boxShadow: "lg",
            flexDirection: "column",
            justifyContent: "space-between",
            my: 2,
            height: "full",
            maxWidth: "full",
          }}
        >
          <Box sx={{ height: "full", width: "full" }}>
            <AspectRatio ratio={4 / 3}>
              {clinic?.heroImage ? (
                <Img
                  fluid={clinic?.heroImage?.fluid}
                  alt={clinic.title}
                  sx={{ width: "full", objectFit: "cover", height: "full" }}
                  loading="lazy"
                />
              ) : (
                <img
                  src="https://www.datocms-assets.com/23576/1664202637-chbuilding.jpg"
                  alt={clinic.title}
                  sx={{ width: "full", objectFit: "cover", height: "full" }}
                  loading="lazy"
                />
              )}
            </AspectRatio>
          </Box>
          <Box sx={{ px: [4], width: "full", height: "full" }}>
            <Styled.h3 sx={{ my: 3 }}>{clinic?.title}</Styled.h3>
            <Box
              as="ul"
              sx={{
                listStyle: "none",
                margin: 0,
                padding: 0,
                fontSize: 0,
                color: "gray.8",
              }}
            >
              {clinic?.offerings?.map(offering => (
                <Styled.li key={offering?.id}>{offering?.service}</Styled.li>
              ))}
            </Box>
          </Box>
          <Button
            sx={{ mt: 3 }}
            as={Link}
            to={`/outpatient-clinics/${clinic.slug}`}
          >
            Learn More
          </Button>
        </Flex>
      </Link>
    </Box>
  )
}

export default ClinicCard
