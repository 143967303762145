/** @jsx jsx */
import { jsx, Box, Button, Flex, Styled } from "theme-ui"
import { format } from "date-fns"
import useSWR from "swr"
import fetcher from "../../utils/fetcher"

import { LinkExt } from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"

const NewsGrid = () => {
  const { data, error } = useSWR(
    `https://mobile.wvumedicine.org/wayfinder/api/news/story/featured/wvu-medicine/childrens-hospital/3`,
    fetcher
  )

  if (error) return <Box>Failed to load</Box>

  if (!data)
    return (
      <Box width="full" height="full">
        Loading...
      </Box>
    )

  return (
    <Box sx={{ width: ["full"] }}>
      <Flex sx={{ flexWrap: "wrap", mx: [0, 0, -2] }}>
        {data.map(({ ...story }) => (
          <Box
            key={story.guid}
            sx={{
              px: [0, 0, 2],
              my: [2, 2, 0],
              width: ["full", "full", "1/3"],
            }}
          >
            <Flex
              sx={{
                bg: "white",
                borderRadius: "default",
                boxShadow: "lg",
                flexDirection: "column",
                justifyContent: "space-between",
                my: 2,
                height: "full",
                maxWidth: "full",
              }}
            >
              <Box sx={{ height: "full", width: "full" }}>
                <img
                  src={story.image}
                  alt={story.headline}
                  sx={{ width: "full" }}
                  width="600px"
                  heigth="337px"
                  loading="lazy"
                />
              </Box>
              <Box sx={{ px: [4], width: "full" }}>
                <Styled.p sx={{ fontSize: 0, mb: 0 }}>{`${format(
                  new Date(story.newsDate),
                  "MM/dd/yyyy"
                )}`}</Styled.p>
                <Styled.h3 sx={{ mb: 2, mt: 1 }}>{story.headline}</Styled.h3>
              </Box>
              <Flex
                sx={{
                  flexDirection: "column",
                  height: "full",
                  justifyContent: "flex-end",
                  px: [4],
                  pb: [3, 4],
                }}
              >
                <LinkExt
                  to={`https://wvumedicine.org/news-feed/news-article/wvu-medicine/childrens-hospital/${story.uniqueHeadline}`}
                >
                  <Button as="p" variant="outline">
                    Full Story{" "}
                    <Box
                      as="span"
                      sx={{ display: "none" }}
                    >{`about ${story.headline}`}</Box>
                  </Button>
                </LinkExt>
              </Flex>
            </Flex>
          </Box>
        ))}
      </Flex>
    </Box>
  )
}

export default NewsGrid
