import React from "react"

const ColorBar = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 648.8 6.4"
    id="colorbar"
    {...props}
  >
    <path fill="#eb8b23" d="M0 0h162.2v6.4H0z" />
    <path fill="#2060ad" d="M162.2 0h162.2v6.4H162.2z" />
    <path fill="#e9ab21" d="M324.4 0h162.2v6.4H324.4z" />
    <path fill="#bf3b34" d="M486.6 0h162.2v6.4H486.6z" />
  </svg>
)

export default ColorBar
