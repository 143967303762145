/** @jsx jsx */
import { jsx, Box } from "theme-ui"
import React from "react"
import useSWR from "swr"
import { reverse } from "lodash"

import fetcher from "../../utils/fetcher"

const SpecialistsListDir = props => {
  const { data, error } = useSWR(
    `https://mobile.wvumedicine.org/wayfinder/api/findadoc/subgroup/${props.id}`,
    fetcher
  )

  if (error) return <Box>Failed to load</Box>

  if (!data)
    return (
      <Box width="full" height="full">
        Loading...
      </Box>
    )

  const Providers = data.map(provider => provider)

  return (
    <>
      {Providers.length > 0 && <Box as="h3">Providers</Box>}
      <Box
        as="ul"
        m={0}
        p={0}
        sx={{ listStyle: "none", margin: 0, padding: 0, fontSize: 0 }}
      >
        {reverse(
          Providers.map(({ ...provider }) => (
            <Box
              as="li"
              key={provider.personID}
              sx={{
                borderBottomColor: "gray.5",
                borderBottomStyle: "solid",
                borderBottomWidth: 1,
                py: 1,
                a: {
                  textDecoration: "none",
                },
                "a:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              <Box as="a" href={provider.webpage}>
                <Box as="span" sx={{ fontWeight: "bold" }}>
                  {`${provider.firstName} ${provider.lastName}`}
                </Box>{" "}
                {provider.credentials.map(({ credentialName }) => (
                  <>{credentialName} </>
                ))}
              </Box>
            </Box>
          ))
        )}
      </Box>
    </>
  )
}

export default SpecialistsListDir
