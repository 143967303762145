import theme from "@giraldomac/gatsby-theme-wvumedicine/src/gatsby-plugin-theme-ui/index"

const childrenColors = [null, "#ED8B00", "#005EB8", "#EAAA00", "#BE3A34"]

export default {
  ...theme,
  colors: {
    ...theme.colors,
    primaryHover: "#0033A0",
    secondaryHover: "#BE3A34",
    children: [null, "#ED8B00", "#005EB8", "#EAAA00", "#BE3A34"],
    warmgray: "#f1f1f1",
    babyblue: "#cce6fa",
  },
  buttons: {
    ...theme.buttons,
    primary: {
      ...theme.buttons.simple,
      bg: "#005EB8",
      textTransform: "uppercase",
      fontSize: 0,
      letterSpacing: "1px",
      transition: "all .15s ease",
      "&:hover": {
        bg: "primaryHover",
      },
    },
    secondary: {
      ...theme.buttons.simple,
      bg: "#ED8B00",
      textTransform: "uppercase",
      fontSize: 0,
      letterSpacing: "1px",
      transition: "all .15s ease",
      "&:hover": {
        bg: "secondaryHover",
      },
    },
    outline: {
      ...theme.buttons.outline,
      borderColor: childrenColors[2],
      color: childrenColors[2],
      textTransform: "uppercase",
      fontSize: 0,
      letterSpacing: "1px",
      transition: "all .15s ease",
      "&:hover": {
        bg: childrenColors[2],
        color: "white",
      },
    },
    inverted: {
      ...theme.buttons.outline,
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "white",
      color: "white",
      bg: "transparent",
      textTransform: "uppercase",
      fontSize: 0,
      letterSpacing: "1px",
      transition: "all .15s ease",
      "&:hover": {
        bg: "white",
        color: "black",
      },
    },
  },
  cards: {
    ...theme.cards,
    stacked: {
      ...theme.cards.stacked,
      height: "full",
      width: ["full"],
    },
  },
  sizes: {
    ...theme.sizes,
    sidebar: 256,
  },
  styles: {
    ...theme.styles,
    a: {
      ...theme.styles.a,
      color: childrenColors[2],
      ":hover": {
        textDecoration: "underline",
      },
    },
    h1: {
      ...theme.styles.h1,
      color: "white",
      lineHeight: "tight",
      textShadow: `2px 2px 4px rgba(0,0,0,0.25)`,
    },
    h2: {
      ...theme.styles.h2,
      color: childrenColors[2],
      fontSize: [4],
      lineHeight: "snug",
      mt: 4,
    },
    h3: {
      ...theme.styles.h3,
      fontSize: [3],
      fontWeight: "bold",
    },
    h4: {
      ...theme.styles.h4,
    },
    h5: {
      ...theme.styles.h5,
    },
    h6: {
      ...theme.styles.h2,
      color: childrenColors[2],
      "::after": {
        backgroundColor: "secondary",
        content: '""',
        display: "block",
        width: "4rem",
        height: "4px",
        my: 4,
      },
    },
    listReset: {
      margin: 0,
      padding: 0,
      listStyle: "none",
    },
    quickLinks: {
      fontFamily: "heading",
      "a:link, a": {
        color: "inherit",
        display: "block",
        textDecoration: "none",
      },
      "&:hover": {
        transform: "scale(1.05)",
        transitionProperty: "transform",
        transitionDuration: "0.15s",
      },
      "a:hover": {
        boxShadow: "lg",
      },
      span: {
        fontSize: [2],
        fontWeight: "bold",
        lineHeight: 1,
        maxWidth: "90%",
        minWidth: "90%",
      },
    },
    quickLinksAlt: {
      "a:link, a": {
        color: "inherit",
        display: "block",
        textDecoration: "none",
        width: "full",
      },
      "div:hover": {
        boxShadow: "lg",
        transform: "scale(1.05)",
        transitionProperty: "transform",
        transitionDuration: "0.15s",
      },
      span: {
        fontSize: [1],
        fontWeight: "bold",
        lineHeight: 1,
        maxWidth: "90%",
        textTransform: "uppercase",
        letterSpacing: "wider",
      },
    },
    twoCols: {
      ...theme.styles.twoCols,
      columnCount: [1, 1, 2],
      columnGap: [0, 0, 5],
      "*:first-child": {
        marginTop: 0,
      },
    },
  },
}
