/** @jsx jsx */
import { jsx, Box } from "theme-ui"

function HeroGradient(props) {
  return (
    <Box
      sx={{
        background: `linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 37%, rgba(0,0,0,0.65) 100%)`,
      }}
      {...props}
    >
      {props.children}
    </Box>
  )
}

export default HeroGradient
