/** @jsx jsx */
import { jsx, Box } from "theme-ui"

const ClinicService = ({ item }) => {
  return (
    <Box
      as="ul"
      sx={{
        listStyle: "none",
        px: [0, 0, 4],
        my: 2,
        width: ["full", "50%", "1/3", "1/4"],
      }}
    >
      <Box as="li">
        <Box as="h3">{item?.service}</Box>
        <Box
          width={["full"]}
          dangerouslySetInnerHTML={{
            __html: item.subservicesNode.childMarkdownRemark.html,
          }}
        />
      </Box>
    </Box>
  )
}

export default ClinicService
