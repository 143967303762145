import React from "react"

const { compose, withProps, withStateHandlers } = require("recompose")
const { withScriptjs, withGoogleMap, GoogleMap } = require("react-google-maps")

const StyledMapWithAnInfoBox = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyCKOCFVmDjFHWty7y87b0hyh51O-XtKqMQ&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
    center: { lat: 39.653335, lng: -79.958502 },
  }),
  withStateHandlers(
    () => ({
      isOpen: false,
    }),
    {
      onToggleOpen: ({ isOpen }) => () => ({
        isOpen: !isOpen,
      }),
    }
  ),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap
    defaultZoom={16}
    defaultCenter={props.center}
    defaultOptions={{ styles: "" }}
  ></GoogleMap>
))

export default StyledMapWithAnInfoBox
