/** @jsx jsx */
import { jsx, Box, Button, Link as LinkExt } from "theme-ui"

const CallToActionSchedule = () => {
  return (
    <Box
      sx={{ textAlign: "center", display: "block" }}
    >
      <Box as="p">
        <Box as="span" sx={{ fontWeight: "bold" }}>
          CONTACT
        </Box>{" "}
        <br />
        <a href="tel:18559882273">1-855-WVU-CARE(2273)</a>
      </Box>
      <LinkExt href="https://wvumedicine.org/online-appointment-primary-care-physician/">
        <Button as="span" my={1} mt={3} sx={{ width: "100%" }}>
          Schedule an Appointment
        </Button>
      </LinkExt>
      <LinkExt href="https://childrens.wvumedicine.org/for-medical-professionals">
        <Button as="span" my={1} sx={{ width: "100%" }} variant="outline">
          Refer a Patient
        </Button>
      </LinkExt>
    </Box>
  )
}

export default CallToActionSchedule
