/** @jsx jsx */
import { jsx, Select } from 'theme-ui'

const SelectDropdownTopic = ({options, settagItem}, props) => {
  return (
    <Select
    defaultValue="Filter by Tag"
    onChange={(e) => {
      settagItem(e.target.value);
      
    }}
      sx={{width: 'full'}}
    >
      <option value="Filter by Topic">Filter by Topic</option>
      {options.map(( {...option}, index ) => (
        <option value={option.title} key={`${option.title}-${index}`}>{ option.title }</option>
        ))}      
    </Select>
  )
}

export default SelectDropdownTopic