/** @jsx jsx */
import { jsx, Box, Styled } from "theme-ui"
import React from "react"
import { Link } from "gatsby"
import { Logos } from "./index"
import SpecialistsListDir from "./SpecialistsListDir"

const CentersOfExcellenceSideNav = ({ page }) => {
  return (
    <Box px={[3]}>
      <Styled.h2 sx={{ lineHeight: 1 }}>{page.title}</Styled.h2>

      <Box
        as="ul"
        sx={{
          py: 3,
          mx: 0,
          px: 0,
          listStyle: "none",
          li: {
            borderBottomColor: "gray.5",
            borderBottomStyle: "solid",
            borderBottomWidth: 1,
            py: 1,
          },
          "li a": {
            textDecoration: "none",
          },
          "li a:hover": {
            textDecoration: "underline",
          },
          width: ["full"],
        }}
      >
        <>
          {page?.conditionsWeTreat && (
            <Box as="li" key={page?.id} sx={{ listStyle: "none" }}>
              <Link
                to={`/about-us/centers-of-excellence/${page?.slug}/conditions`}
              >
                Conditions We Treat
              </Link>
            </Box>
          )}
          {page?.relatedPrograms?.length > 0 && (
            <Box as="li" key={page?.id} sx={{ listStyle: "none" }}>
              <Link
                to={`/about-us/centers-of-excellence/${page?.slug}/programs`}
              >
                Programs
              </Link>

              <Box as="ul" pl={3}>
                {page?.relatedPrograms?.map(({ ...program }) => (
                  <Box
                    as="li"
                    key={program?.id}
                    sx={{ borderStyle: "none!important" }}
                  >
                    <Link
                      to={`/services/programs/${program?.slug}`}
                      sx={{ height: "full", width: "full" }}
                    >
                      {program?.title}
                    </Link>
                  </Box>
                ))}
              </Box>
            </Box>
          )}
          {page?.relatedServices?.length > 0 && (
            <Box as="li" key={page?.id} sx={{ listStyle: "none" }}>
              <Link
                to={`/about-us/centers-of-excellence/${page?.slug}/services`}
              >
                Services
              </Link>
            </Box>
          )}
          {page?.resources && (
            <Box as="li" key={page?.id} sx={{ listStyle: "none" }}>
              <Link
                to={`/about-us/centers-of-excellence/${page?.slug}/resources`}
              >
                Resources
              </Link>
            </Box>
          )}
          {page?.relatedBlogs?.length > 0 && (
            <Box as="li" key={page?.id} sx={{ listStyle: "none" }}>
              <Link to={`/about-us/centers-of-excellence/${page?.slug}/blogs`}>
                Related Blogs
              </Link>
            </Box>
          )}

          {page?.slug === "heart-center" && (
            <Box as="li" sx={{ listStyle: "none" }}>
              <Link to={`/heartbeats-of-hope`}>Heartbeats of Hope</Link>
            </Box>
          )}

          <br />

          {page.meetYourTeamFindADocSpecialtyId && (
            <SpecialistsListDir id={page.meetYourTeamFindADocSpecialtyId} />
          )}
        </>
      </Box>

      {/* Logos */}
      {page?.logos && <Logos logos={page?.logos} />}
    </Box>
  )
}

export default CentersOfExcellenceSideNav
