/** @jsx jsx */
import { jsx, Box } from "theme-ui"
import { LinkExt } from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"
import Img from "gatsby-image/withIEPolyfill"

const Logos = ({ logos }) => {
  return (
    <Box
      sx={{
        my: 3,
      }}
    >
      {logos.map(({ ...logo }) => (
        <Box
          key={logo.id}
          sx={{
            width: ["120px", "120px", "140px"],
            textAlign: "center",
            mx: "auto",
            display: "block",
          }}
        >
          {logo.url ? (
            <LinkExt to={logo.url}>
              <Img fluid={logo?.logo?.fluid} alt=" " objectFit="contain" />
            </LinkExt>
          ) : (
            <Img fluid={logo?.logo?.fluid} alt=" " objectFit="contain" />
          )}
        </Box>
      ))}
    </Box>
  )
}

export default Logos
