/** @jsx jsx */
import { jsx, Box, Text, Link as ExtLink } from "theme-ui"
import React from "react"

const ProgramsDownloads = ({ downloads }) => {
  console.log(downloads)
  return (
    <Box as="ul">
      {downloads?.map(({ ...item }) => (
        <>
          {item?.document?.url ? (
            <Box as="li" key={item?.id}>
              <Text>
                <ExtLink href={item?.document?.url}>{item.title}</ExtLink>
              </Text>
            </Box>
          ) : (
            <Box as="li" key={item?.id}>
              <Text>
                <ExtLink href={item?.externalLink}>{item.title}</ExtLink>
              </Text>
            </Box>
          )}
        </>
      ))}
    </Box>
  )
}

export default ProgramsDownloads
