/** @jsx jsx */
import { jsx, Box, Button, Flex, Styled, AspectRatio } from "theme-ui"
import { Link } from 'gatsby'
import Img from "gatsby-image/withIEPolyfill"

const PostCard = ({post, ...props}) => {
  return (
    
    
      <Box sx={{px: [0, 0, 2],
              my: [2],
              width: props.width}}>
        <Flex
          sx={{
            bg: "white",
            borderRadius: "default",
            boxShadow: "lg",
            flexDirection: "column",
            justifyContent: "space-between",
            my: 2,
            height: "full",
            maxWidth: "full",
          }}
        >
          <Box sx={{ height: "full", width: "full" }}>
          <AspectRatio ratio={4 / 3}>
            
            {post?.blogImage ?
              <Img
                fluid={post?.blogImage?.fluid}
                alt={post.title}
                sx={{ width: "full", objectFit: 'cover', height: "full" }}
                loading="lazy"
              />
              :
              <img
                src="https://www.datocms-assets.com/23576/1664202637-chbuilding.jpg"
                alt={post.title}
                sx={{ width: "full", objectFit: 'cover', height: "full" }}
                loading="lazy"
              />
            }
            </AspectRatio>
          </Box>
          <Box sx={{ px: [4], width: "full" }}>
          <Styled.h3 sx={{ my: 3 }}>{post?.title}</Styled.h3>
          <Styled.p sx={{ fontSize: 0, mb: 0 }}>{post?.author?.name}<br />{post?.date}</Styled.p> 
          
          </Box>
          <Flex
            sx={{
              flexDirection: "column",
              height: "full",
              justifyContent: "flex-end",
              px: [4],
              pb: [3, 4],
            }}
          >
            <Link
              to={`/blog/${post.slug}`}
            >
              <Button as="p" variant="outline">
                Full Story{" "}
                <Box
                  as="span"
                  sx={{ display: "none" }}
                >{`about ${post.title}`}</Box>
              </Button>
            </Link>
          </Flex>
        </Flex>
      </Box>
    
    
  )
}

export default PostCard
