/** @jsx jsx */
import { jsx, Box, Styled } from "theme-ui"
import React from "react"
import { Link } from "gatsby"
import { Logos } from "./index"

const ProgramsNewSideNav = ({ page }) => {
  return (
    <Box px={[3]} data-datocms-noindex>
      <Styled.h2 sx={{ lineHeight: 1 }}><Link to={`/services/programs/${page?.slug}`}>{page?.title}</Link></Styled.h2>

      <Box
        as="ul"
        sx={{
          py: 3,
          mx: 0,
          px: 0,
          listStyle: "none",
          li: {
            borderBottomColor: "gray.5",
            borderBottomStyle: "solid",
            borderBottomWidth: 1,
            py: 1,
          },
          "li a": {
            textDecoration: "none",
          },
          "li a:hover": {
            textDecoration: "underline",
          },
          width: ["full"],
        }}
      >
        <>
          
          {page?.conditionsWeTreat && (
            <Box as="li" key={page?.id} sx={{ listStyle: "none" }}>
              <Link to={`/services/programs/${page?.slug}/conditions`}>
                Conditions We Treat
              </Link>
            </Box>
          )}

          {page?.diagnostics && (
            <Box as="li" key={page?.id} sx={{ listStyle: "none" }}>
              <Link to={`/services/programs/${page?.slug}/diagnostics`}>
                Diagnostics
              </Link>
            </Box>
          )}

          {page?.preparingForYourAppointment && (
            <Box as="li" key={page?.id} sx={{ listStyle: "none" }}>
              <Link to={`/services/programs/${page?.slug}/preparing-for-your-appointment`}>
                Preparing for Your Appointment
              </Link>
            </Box>
          )}

          {page?.treatments && (
            <Box as="li" key={page?.id} sx={{ listStyle: "none" }}>
              <Link to={`/services/programs/${page?.slug}/treatments`}>
                Treatments
              </Link>
            </Box>
          )}

          {page?.findADocSpecialtyId && (
            <Box as="li" key={page?.id} sx={{ listStyle: "none" }}>
              <Link to={`/services/programs/${page?.slug}/providers`}>
                Meet Your Team
              </Link>
            </Box>
          )}

          {page?.faqs?.length > 0 && (
            <Box as="li" key={page?.id} sx={{ listStyle: "none" }}>
              <Link to={`/services/programs/${page?.slug}/faqs`}>FAQs</Link>
            </Box>
          )}

          {page?.downloads?.length > 0 && (
            <Box as="li" key={page?.id} sx={{ listStyle: "none" }}>
              <Link to={`/services/programs/${page?.slug}/downloads`}>
                Resources & Downloads
              </Link>
            </Box>
          )}

          {page?.relatedPrograms?.length > 0 && (
            <Box as="li" key={page?.id} sx={{ listStyle: "none" }}>
              <Link to={`/services/programs/${page?.slug}/programs`}>
                Related Programs
              </Link>
            </Box>
          )}

          {page?.relatedServices?.length > 0 && (
            <Box as="li" key={page?.id} sx={{ listStyle: "none" }}>
              <Link to={`/services/programs/${page?.slug}/services`}>
                Related Services
              </Link>
            </Box>
          )} 

          {page?.clinicLocations && (
            <Box as="li" key={page?.id} sx={{ listStyle: "none" }}>
              <Link to={`/services/programs/${page?.slug}/clinic-locations`}>
                Clinic Locations
              </Link>
            </Box>
          )} 
          
        </>
      </Box>

      {/* Logos */}
      {page?.logos?.length > 0 && <Logos logos={page?.logos} />}
    </Box>
  )
}

export default ProgramsNewSideNav
