/** @jsx jsx */
import { jsx, Select } from "theme-ui"

const SelectDropdownCounties = ({ options, setcountyItem }, props) => {
  return (
    <Select
      defaultValue="Filter by County"
      onChange={e => {
        setcountyItem(e.target.value)
      }}
      sx={{ width: ["full", "full", "1/3"] }}
    >
      <option value="Filter by County">Filter by County</option>
      {options?.map(({ ...option }, index) => (
        <option value={option?.title} key={`${option.title}-${index}`}>
          {option.title}
        </option>
      ))}
    </Select>
  )
}

export default SelectDropdownCounties
