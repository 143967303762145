/** @jsx jsx */
import { jsx, Box } from "theme-ui"
import React, { useState, useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faWindowClose, faSearch } from "@fortawesome/pro-regular-svg-icons"
import DatoCmsSearch from "datocms-search/dist/datocms-search.widget"

const SearchContainer = props => {
  useEffect(() => {
    const client = new DatoCmsSearch(
      "3168d2a986d9a2e11101d625706e0e",
      "production"
    ).addWidget("#search")
    const resultsLabel = document.querySelector(
      ".datocms-widget__no-results__label"
    )
    if (resultsLabel) {
      resultsLabel.innerHTML = "WVU Medicine Children's"
    }
  })
  const [isSearchBoxOn, setSearchOn] = useState(false)
  const toggleSearchBox = () => setSearchOn(!isSearchBoxOn)

  return (
    <Box
      sx={{
        position: "absolute",
        display: "block",
        top: 0,
        left: 0,
        width: "full",
        zIndex: 100,
      }}
      {...props}
    >
      <button
        onClick={toggleSearchBox}
        aria-label={`${isSearchBoxOn ? "close search" : "open search"}`}
        sx={{
          background: "transparent",
          border: "none",
          display: "block",
          width: "50px",
          height: "50px",
          cursor: "pointer",
          position: "absolute",
          top: "0.15rem",
          right: isSearchBoxOn ? "0.2rem" : ["5em", "5em", "0.2rem"],
          zIndex: 100,
          "&:hover:not(.touch)": {
            background: "transparent",
            border: "none",
            outline: "none",
            transform: "scale(1.25, 1.25)",
            transitionProperty: "transform",
            transitionDuration: "0.15s",
          },
          ":focus": {
            background: "transparent",
            border: "none",
            outline: "none",
          },
        }}
      >
        <FontAwesomeIcon
          icon={isSearchBoxOn ? faWindowClose : faSearch}
          sx={{ color: isSearchBoxOn ? "gray.7" : "white" }}
          style={{ fontSize: "1.5em" }}
        />
      </button>
      <Box
        id="search"
        sx={{
          display: isSearchBoxOn ? "block" : "none",
          position: "relative",
          pt: [4, 4, 5],
          backgroundColor: "aliceblue",
          heigth: "full",
          width: "full",
          zIndex: 20,
        }}
      />
    </Box>
  )
}

export default SearchContainer
