/** @jsx jsx */
import { jsx, Heading } from "theme-ui"

function SectionHeading(props) {
  return (
    <Heading
      sx={{
        color: "inherit",
        fontFamily: "body",
        fontSize: [1],
        letterSpacing: "widest",
        mb: 4,
        textTransform: "uppercase",
      }}
      {...props}
    >
      {props.children}
    </Heading>
  )
}

export default SectionHeading
