/** @jsx jsx */
import { jsx, Box, Container } from "theme-ui"
import { useState } from "react"

import {
  Nav,
  NavSecondary,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout"
import { Logo } from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"

const Toggle = ({ color = "white" }) => {
  const [isToggledOn, setToggle] = useState(false)
  const toggle = () => setToggle(!isToggledOn)

  return (
    <Box
      className="mobile-nav"
      sx={{
        display: ["block", "block", "none"],
        cursor: "pointer",
        width: "50px",
      }}
    >
      <button
        onClick={toggle}
        aria-label={`${isToggledOn ? "close menu" : "open menu"}`}
        sx={{
          zIndex: 30,
          top: "-5px",
          right: "-5px",
          position: "relative",
          background: "transparent",
          border: "none",
          cursor: "pointer",
          padding: "8px 15px",
          ":hover:not(.touch)": {
            background: "transparent",
            border: "none",
            outline: "none",
          },
          ":focus": {
            background: "transparent",
            border: "none",
            outline: "none",
          },
        }}
      >
        <Box
          sx={{
            width: "24px",
            height: "2px",
            position: "absolute",
            left: 0,
            background: isToggledOn ? "transparent" : color,
            transition: "all 250ms cubic-bezier(0.86, 0, 0.07, 1)",
            "::before": {
              content: '" "',
              top: "-8px",
              width: "24px",
              height: "2px",
              background: isToggledOn ? "white" : color,
              position: "absolute",
              left: 0,
              transform: isToggledOn ? "rotate(45deg); top:0" : "rotate(0)",
              transition: "all 250ms cubic-bezier(0.86, 0, 0.07, 1)",
            },
            "::after": {
              top: "8px",
              content: '" "',
              width: "24px",
              height: "2px",
              background: isToggledOn ? "white" : color,
              position: "absolute",
              left: 0,
              transform: isToggledOn ? "rotate(-45deg); top:0" : "rotate(0)",
              transition: "all 250ms cubic-bezier(0.86, 0, 0.07, 1)",
            },
          }}
        />
      </button>
      {isToggledOn ? (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            height: `calc(100% + 100vh)`,
            maxWidth: "380px",
            width: "270px",
            display: "flex",
            backgroundColor: "secondary",
            opacity: 1,
            transition: `opacity 0.30s, visibility 0.30s`,
            zIndex: 20,
          }}
        >
          <Container
            sx={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "nowrap",
              padding: "6em 1em 0 1em",
              ul: {
                flexDirection: "column",
              },
              "li a": {
                color: "black",
                borderBottom: `1px solid rgba(255, 255, 255, 0.1)`,
                display: "block",
                width: "100%",
                fontSize: 2,
                padding: "0.5rem 0",
                textDecoration: "none",
              },
              "li a.active": {
                color: "primary",
              },
              "li a:hover": {
                color: "primary",
              },
              "li a svg": {
                display: "none",
              },
              "svg *": {
                fill: "black",
              },
            }}
          >
            <Logo
              sx={{
                paddingBottom: "2em",
                maxWidth: "280px",
                margin: "0 auto",
              }}
            />
            <Nav sx={{ margin: 0, padding: 0 }} />
            <NavSecondary sx={{ margin: 0, padding: 0 }} />
          </Container>
        </Box>
      ) : (
        <Box
          sx={{
            opacity: 0,
            visibility: "hidden",
          }}
        />
      )}
    </Box>
  )
}

export default Toggle
