/** @jsx jsx */
import { jsx, Box, Flex, Grid, Styled } from "theme-ui"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

import { SectionHeading } from "../../components/elements"

const LeadershipGrid = () => {
  const data = useStaticQuery(graphql`
    {
      allDatoCmsLeadership(
        sort: { order: ASC, fields: position }
        filter: { meta: { status: { eq: "published" } } }
      ) {
        edges {
          node {
            slug
            position
            roleTitle
            credentials
            id
            fullName
            portrait {
              fluid(
                maxWidth: 240
                imgixParams: { fm: "jpg", auto: "compress" }
              ) {
                ...GatsbyDatoCmsFluid
              }
            }
            meta {
              status
            }
          }
        }
      }
    }
  `)
  return (
    <Flex sx={{ flexWrap: "wrap" }}>
      <Box sx={{ width: ["full"], textAlign: "center" }}>
        <SectionHeading>Leadership</SectionHeading>
      </Box>

      <Box className="leadership-grid" sx={{ width: ["full"] }}>
        <Grid gap={3} columns={[1, 2, 3]} sx={{ gridAutoRows: "1fr" }}>
          {data?.allDatoCmsLeadership?.edges.map(({ node: leader }) => (
            <Flex
              key={leader.id}
              sx={{
                alignItems: "center",
                backgroundColor: "white",
                borderColor: "gray.3",
                borderRadius: "default",
                borderStyle: "solid",
                borderWidth: "1px",
                flexWrap: "no-wrap",
                justifyContent: "space-between",
              }}
              variant="styles.quickLinks"
            >
              <Link
                to={`/about-us/leadership/${leader.slug}`}
                sx={{ height: "full", width: "full" }}
              >
                <Flex
                  sx={{
                    alignItems: "center",
                    flexWrap: ["no-wrap", "wrap", "wrap"],
                    height: "full",
                    justifyContent: ["space-betwen"],
                    px: 2,
                    py: [2, 3],
                    textAlign: ["left", "center"],
                    width: "full",
                  }}
                >
                  <Box sx={{ width: ["1/5", "full"] }}>
                    <Box sx={{ width: ["full"] }}>
                      <Img
                        fluid={leader.portrait.fluid}
                        alt={`${leader.fullName} Portrait`}
                        objectFit="cover"
                        objectPosition="50% 50%"
                        sx={{
                          borderRadius: "full",
                          mx: ["auto"],
                          height: 60,
                          width: 60,
                        }}
                      />
                    </Box>
                  </Box>
                  <Box sx={{ width: ["4/5", "full"] }}>
                    <Styled.h3 sx={{ lineHeight: 1 }}>
                      {leader.fullName}
                      <span sx={{ fontSize: 0 }}>
                        {leader.credentials && `, ${leader.credentials}`}
                      </span>
                    </Styled.h3>
                    <Box as="p" sx={{ fontSize: 0 }}>
                      {leader.roleTitle && leader.roleTitle}
                    </Box>
                  </Box>
                </Flex>
              </Link>
            </Flex>
          ))}
        </Grid>
      </Box>
    </Flex>
  )
}

export default LeadershipGrid
