/** @jsx jsx */
import { Link } from "gatsby"
import { jsx, Box } from "theme-ui"

const CentersOfExcellenceRelatedServices = ({ services }) => {
  return (
    <Box as="ul" mt={[4]}>
      {services?.map(({ ...service }) => (
        <li>
          <Link to={`/services/specialty-care/${service?.slug}`}>
            {service?.title}
          </Link>
        </li>
      ))}
    </Box>
  )
}

export default CentersOfExcellenceRelatedServices
