/** @jsx jsx */
import { jsx, Box, Button, Card, Flex, Styled, Text } from "theme-ui"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Slider from "react-slick"
import { endsWith, shuffle } from "lodash"

import { PrevArrow, NextArrow } from "../elements"

const PatientStory = () => {
  const settings = {
    adaptiveHeight: "true",
    arrows: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  }

  const data = useStaticQuery(graphql`
    {
      allDatoCmsPatientStory(
        filter: { meta: { status: { eq: "published" } } }
      ) {
        edges {
          node {
            image {
              fluid(
                maxWidth: 800
                imgixParams: { fm: "jpg", auto: "compress" }
              ) {
                ...GatsbyDatoCmsFluid
              }
            }
            contentNode {
              childMarkdownRemark {
                excerpt(truncate: true, pruneLength: 140)
              }
            }
            slug
            title
            id
            meta {
              status
            }
          }
        }
      }
    }
  `)
  return (
    <Slider {...settings}>
      {shuffle(data.allDatoCmsPatientStory.edges).map(({ node: story }) => (
        <Box key={story.id} sx={{ px: [0, 4, 5], textAlign: "left" }}>
          <Card variant="full" sx={{ bg: "children.4" }}>
            <Img
              fluid={story.image.fluid}
              alt={`${story.title}'s portrait`}
              sx={{
                objectFit: "cover",
                width: ["full", "full", "1/2"],
                bg: "gray.4",
              }}
            />
            <Flex
              sx={{
                justifyContent: "center",
                flexDirection: "column",
                flexWrap: "wrap",
                width: ["full", "full", "1/2"],
                p: 4,
              }}
            >
              <Styled.p
                sx={{
                  color: "white",
                  fontSize: 0,
                  fontWeight: "bold",
                  textTransform: "uppercase",
                }}
              >
                Patient Story
              </Styled.p>
              <Styled.h3 sx={{ color: "white" }}>{story.title}</Styled.h3>
              <Text
                dangerouslySetInnerHTML={{
                  __html: story.contentNode.childMarkdownRemark.excerpt,
                }}
                sx={{ mb: 3 }}
              />
              <Link to={`/giving/patient-stories/${story.slug}`}>
                <Button
                  as="span"
                  variant="inverted"
                  sx={{
                    "&:hover": {
                      color: "children.4",
                    },
                  }}
                >
                  {endsWith(story.title, "s")
                    ? `${story.title}' Story`
                    : `${story.title}'s Story`}
                </Button>
              </Link>
            </Flex>
          </Card>
        </Box>
      ))}
    </Slider>
  )
}

export default PatientStory
