/** @jsx jsx */
import { jsx, Box,  Flex,  Text, Styled, AspectRatio } from "theme-ui"

import Img from "gatsby-image/withIEPolyfill"

const AuthorCard = ({author}) => {
  return (
      <Flex
        sx={{    
        width: ["full"],flexWrap: "wrap",
        mx:[0,0,-3]
        }}
     >
          <Box sx={{ width: ["full", 'full', '1/3'], px:[0,0,3] }}>
            <AspectRatio ratio={3 / 4}>
              <Img
                fluid={author?.portrait?.fluid}
                alt={author?.name}
                sx={{ width: "full", objectFit: 'cover', height: "full" }}
                
                loading="lazy"
              />
            </AspectRatio>
          </Box>

      <Box sx={{ width: ["full", 'full', '2/3'], px: [0, 0, 3] }}>
        <Styled.h2 as="h1" sx={{ mb: 3, mt: 0 }}>About the Author</Styled.h2>

            <Text
              sx={{ width: ["full"] }}
              dangerouslySetInnerHTML={{
                __html: author?.bioNode?.childMarkdownRemark.html,
              }}
        />
        </Box>
      </Flex>
  )
}

export default AuthorCard