/** @jsx jsx */
import { jsx, Link as LinkStyled } from "theme-ui"

const LinkExt = props => (
  <LinkStyled
    href={props.to}
    rel="noopener noreferrer"
    target={props.target}
    {...props}
  />
)

export default LinkExt
