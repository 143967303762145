/** @jsx jsx */
import { jsx, Box, Container, Styled } from "theme-ui"
import React from "react"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import BackgroundImage from "gatsby-background-image"

import { Hero } from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"


import {
  ColorBar,
  HeroGradient,
} from "./"


const HeroPrograms = ({page, crumbs}) => {
  return (
    <Hero data-datocms-noindex>
      
      {page?.heroImage ? (
      <BackgroundImage Tag="div" fluid={page?.heroImage?.fluid }>
          <HeroGradient zIndex={10} position="relative">
            <Container sx={{ px: "1em", py: [5, 5, 6] }}>
              <Styled.h1>{page.title}</Styled.h1>
              <Breadcrumb
                crumbs={crumbs}
                crumbSeparator=" / "
                crumbLabel={page.title}
              />
            </Container>
          </HeroGradient>
          <ColorBar sx={{ position: "absolute", bottom: 0, left: 0 }} />
        </BackgroundImage>
        
      ): (
          
        <Box>
          <HeroGradient zIndex={10} position="relative">
              <Container sx={{ px: "1em", py: [5, 5, 6] }}>
                <Styled.h1>{page.title}</Styled.h1>
                <Breadcrumb
                  crumbs={crumbs}
                  crumbSeparator=" / "
                  crumbLabel={page.title}
                />
              </Container>
            </HeroGradient>
            <ColorBar sx={{ position: "absolute", bottom: 0, left: 0 }} />
        </Box>
      
          
      )}
          
      </Hero>
  )
}

export default HeroPrograms