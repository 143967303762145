/** @jsx jsx */
import { jsx, Box, Container, Flex, Text } from "theme-ui"
import { Link } from "gatsby"
import { uniqueId } from "lodash"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faAddressBook,
  faBed,
  faChevronRight,
  faChild,
  faHeart,
  faLaptopMedical,
  faMapMarkerAlt,
  faStethoscope,
  faUserCircle,
  faUserMd,
} from "@fortawesome/pro-regular-svg-icons"

import { LinkExt } from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"
import { Section } from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout"

import { LabelIcon, PageLabel, SectionHeading } from "./"

const QuickLinksHome = () => {
  return (
    <Section id="quicklinks" sx={{ backgroundColor: ["warmgray"] }}>
      <Container sx={{ px: "1em" }}>
        <Flex sx={{ flexWrap: "wrap", justifyContent: "center" }}>
          <Text sx={{ width: "full", textAlign: "center" }}>
            <SectionHeading>For your Convenience</SectionHeading>
          </Text>
          <Flex sx={{ width: "full", flexWrap: "wrap", mx: [0, -2] }}>
            <Box
              key={`${uniqueId("card_id_")}`}
              sx={{ width: ["full", "1/2", "1/3"], px: [0, 2] }}
              variant="styles.quickLinks"
            >
              <LinkExt href="https://mywvuchart.com/MyChart/Authentication/Login?">
                <PageLabel sx={{ bg: "white" }}>
                  <LabelIcon bgColor="#be3a34" title="MyWVUChart">
                    <FontAwesomeIcon icon={faLaptopMedical} className="fa-lg" />
                  </LabelIcon>
                  <FontAwesomeIcon icon={faChevronRight} className="fa-lg" />
                </PageLabel>
              </LinkExt>
            </Box>

            <Box
              key={`${uniqueId("card_id_")}`}
              sx={{ width: ["full", "1/2", "1/3"], px: [0, 2] }}
              variant="styles.quickLinks"
            >
              <Link to="/patients-visitors/your-hospital-stay">
                <PageLabel sx={{ bg: "white" }}>
                  <LabelIcon bgColor="#ed8b00" title="Your Stay">
                    <FontAwesomeIcon icon={faBed} className="fa-lg" />
                  </LabelIcon>
                  <FontAwesomeIcon icon={faChevronRight} className="fa-lg" />
                </PageLabel>
              </Link>
            </Box>

            <Box
              key={`${uniqueId("card_id_")}`}
              sx={{ width: ["full", "1/2", "1/3"], px: [0, 2] }}
              variant="styles.quickLinks"
            >
              <Link to="/patients-visitors/programs">
                <PageLabel sx={{ bg: "white" }}>
                  <LabelIcon bgColor="#CD4C08" title="Programs">
                    <FontAwesomeIcon icon={faUserCircle} className="fa-lg" />
                  </LabelIcon>
                  <FontAwesomeIcon icon={faChevronRight} className="fa-lg" />
                </PageLabel>
              </Link>
            </Box>

            <Box
              key={`${uniqueId("card_id_")}`}
              sx={{ width: ["full", "1/2", "1/3"], px: [0, 2] }}
              variant="styles.quickLinks"
            >
              <Link to="/services/specialty-care#services">
                <PageLabel sx={{ bg: "white" }}>
                  <LabelIcon bgColor="#005EB8" title="Services Directory">
                    <FontAwesomeIcon icon={faStethoscope} className="fa-lg" />
                  </LabelIcon>
                  <FontAwesomeIcon icon={faChevronRight} className="fa-lg" />
                </PageLabel>
              </Link>
            </Box>

            <Box
              key={`${uniqueId("card_id_")}`}
              sx={{ width: ["full", "1/2", "1/3"], px: [0, 2] }}
              variant="styles.quickLinks"
            >
              <Link to="/for-medical-professionals/refer-patient">
                <PageLabel sx={{ bg: "white" }}>
                  <LabelIcon bgColor="#9ABEAA" title="Refer a Patient">
                    <FontAwesomeIcon icon={faChild} className="fa-lg" />
                  </LabelIcon>
                  <FontAwesomeIcon icon={faChevronRight} className="fa-lg" />
                </PageLabel>
              </Link>
            </Box>

            <Box
              key={`${uniqueId("card_id_")}`}
              sx={{ width: ["full", "1/2", "1/3"], px: [0, 2] }}
              variant="styles.quickLinks"
            >
              <Link to="/for-medical-professionals#specialists-directory">
                <PageLabel sx={{ bg: "white" }}>
                  <LabelIcon bgColor="#005EB8" title="Specialists Directory">
                    <FontAwesomeIcon icon={faAddressBook} className="fa-lg" />
                  </LabelIcon>
                  <FontAwesomeIcon icon={faChevronRight} className="fa-lg" />
                </PageLabel>
              </Link>
            </Box>

            <Box
              key={`${uniqueId("card_id_")}`}
              sx={{ width: ["full", "1/2", "1/3"], px: [0, 2] }}
              variant="styles.quickLinks"
            >
              <LinkExt href="https://give.wvu.edu/give/430998/#!/donation/checkout">
                <PageLabel sx={{ bg: "white" }}>
                  <LabelIcon bgColor="#ED8B00" title="Giving">
                    <FontAwesomeIcon icon={faHeart} className="fa-lg" />
                  </LabelIcon>
                  <FontAwesomeIcon icon={faChevronRight} className="fa-lg" />
                </PageLabel>
              </LinkExt>
            </Box>

            <Box
              key={`${uniqueId("card_id_")}`}
              sx={{ width: ["full", "1/2", "1/3"], px: [0, 2] }}
              variant="styles.quickLinks"
            >
              <LinkExt href="https://wvumedicine.org/find-a-doctor/">
                <PageLabel sx={{ bg: "white" }}>
                  <LabelIcon bgColor="#CD4C08" title="Find a Doctor">
                    <FontAwesomeIcon icon={faUserMd} className="fa-lg" />
                  </LabelIcon>
                  <FontAwesomeIcon icon={faChevronRight} className="fa-lg" />
                </PageLabel>
              </LinkExt>
            </Box>

            <Box
              key={`${uniqueId("card_id_")}`}
              sx={{ width: ["full", "1/2", "1/3"], px: [0, 2] }}
              variant="styles.quickLinks"
            >
              <Link to="/patients-visitors/visitor-resources">
                <PageLabel sx={{ bg: "white" }}>
                  <LabelIcon bgColor="#ED8B00" title="Parking and Directions">
                    <FontAwesomeIcon icon={faMapMarkerAlt} className="fa-lg" />
                  </LabelIcon>
                  <FontAwesomeIcon icon={faChevronRight} className="fa-lg" />
                </PageLabel>
              </Link>
            </Box>
          </Flex>
        </Flex>
      </Container>
    </Section>
  )
}

export default QuickLinksHome
