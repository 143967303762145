import React from "react"

export const RightArrow = props => {
  return (
    <svg
      focusable="false"
      data-prefix="far"
      data-icon="chevron-right"
      className="svg-inline--fa fa-chevron-right fa-w-8"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 512"
      {...props}
    >
      <path
        fill="currentColor"
        d="M24.707 38.101L4.908 57.899c-4.686 4.686-4.686 12.284 0 16.971L185.607 256 4.908 437.13c-4.686 4.686-4.686 12.284 0 16.971L24.707 473.9c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L41.678 38.101c-4.687-4.687-12.285-4.687-16.971 0z"
      ></path>
    </svg>
  )
}

export const LeftArrow = props => {
  return (
    <svg
      focusable="false"
      data-prefix="far"
      data-icon="chevron-left"
      className="svg-inline--fa fa-chevron-left"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 512"
    >
      <path
        fill="currentColor"
        d="M231.293 473.899l19.799-19.799c4.686-4.686 4.686-12.284 0-16.971L70.393 256 251.092 74.87c4.686-4.686 4.686-12.284 0-16.971L231.293 38.1c-4.686-4.686-12.284-4.686-16.971 0L4.908 247.515c-4.686 4.686-4.686 12.284 0 16.971L214.322 473.9c4.687 4.686 12.285 4.686 16.971-.001z"
      ></path>
    </svg>
  )
}
