/** @jsx jsx */
import { jsx, Box, Flex, Container } from "theme-ui"
import { Link } from "gatsby"

import {
  Nav,
  NavSecondary,
  MobileNav,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout"
import {
  Logo,
  NavLink,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"

import { SearchContainer } from "../../../../components/elements"

const Header = props => {
  return (
    <Flex as="header" sx={{ flexWrap: "wrap" }} data-datocms-noindex>
      <Box backgroundColor="primary" sx={{ fontSize: [0], width: ["full"] }}>
        <Container sx={{ px: "1em" }}>
          <Flex
            sx={{
              flexWrap: "no-wrap",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ width: ["full", "full", "1/4"] }}>
              <Box
                as="ul"
                sx={{
                  listStyle: "none",
                  margin: 0,
                  padding: 0,
                  justifyContent: ["flex-start", "flex-end"],
                  li: {
                    display: ["inline-flex"],
                  },
                  "li a": {
                    color: "gray.4",
                    paddingTop: "1em",
                    paddingBottom: "1em",
                    textDecoration: "none",
                  },
                  "li a:hover, li a.active": {
                    borderTop: "4px solid white",
                    color: "white",
                  },
                }}
              >
                <NavLink>
                  <a href="https://medicine.hsc.wvu.edu/pediatrics/">
                    Academics
                  </a>
                </NavLink>
                <NavLink>
                  <a href="/" className="active">
                    Healthcare
                  </a>
                </NavLink>
              </Box>
            </Box>
            <NavSecondary
              sx={{
                display: ["none", "none", "flex"],
                justifyContent: ["flex-end"],
                alignItems: ["center"],
                lineHeight: 1,
                a: {
                  alignItems: "center",
                  borderTop: "2px solid transparent",
                  color: "gray.4",
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  textAlign: "center",
                  textDecoration: "none",
                },
                "a:hover": {
                  color: "white",
                },
                svg: {
                  fill: "current",
                  fontSize: "1.15em",
                },
                span: {
                  padding: "0 0 0 4px",
                },
                width: ["0", "0", "3/4"],
                pr: [0, 4, 4, 3],
              }}
            />

            {/* Search Nav */}
            <SearchContainer />

            {/* Mobile Nav */}
            <MobileNav />
          </Flex>
        </Container>
      </Box>

      {/* Main Nav */}
      <Box
        className="header-nav"
        sx={{
          backgroundColor: "white",
          overflow: "hidden",
          py: [2],
          width: ["full"],
        }}
      >
        <Container sx={{ px: "1em" }}>
          <Flex
            sx={{
              flexWrap: "wrap",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: ["full", "full", "1/4"],
                a: {
                  padding: "0",
                  margin: "0",
                },
                ".logo": {
                  maxWidth: "200px",
                  display: "block",
                  py: "2",
                },
              }}
            >
              <Link to="/" aria-label="WVU Medicine Logo">
                <Logo />
              </Link>
            </Box>

            <Box>
              <Nav
                sx={{
                  display: ["none", "none", "block"],
                  textAlign: "right",
                  a: {
                    color: "gray.7",
                    textDecoration: "none",
                  },
                  "a:hover, a.active": {
                    color: "black",
                  },
                }}
              />
            </Box>
          </Flex>
        </Container>
      </Box>
    </Flex>
  )
}

export default Header
