/** @jsx jsx */
import { jsx, Box } from "theme-ui"
import { Link } from "gatsby"

import { NavLink } from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements/"

const Nav = props => (
  <Box as="ul" {...props}>
    <NavLink>
      <Link to="/about-us" activeClassName="active">
        About Us
      </Link>
    </NavLink>
    <NavLink>
      <Link to="/patients-visitors" activeClassName="active">
        Patients & Visitors
      </Link>
    </NavLink>
    <NavLink>
      <Link to="/services" activeClassName="active">
        Services
      </Link>
    </NavLink>
    <NavLink>
      <Link to="/for-medical-professionals" activeClassName="active">
        For Medical Professionals
      </Link>
    </NavLink>
    <NavLink>
      <Link to="/blog" activeClassName="active">
        Blog
      </Link>
    </NavLink>
    <NavLink>
      <Link to="/contact-us" activeClassName="active">
        Contact Us
      </Link>
    </NavLink>
  </Box>
)

export default Nav
