/** @jsx jsx */
import { jsx, Box, Button, Card, Flex, Styled, Text } from "theme-ui"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Slider from "react-slick"
import { shuffle } from "lodash"

import { PrevArrow, NextArrow } from "../elements"

const HighlightedProvider = () => {
  const settings = {
    adaptiveHeight: "true",
    arrows: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  }

  const data = useStaticQuery(graphql`
    query highlightedProvider {
      allDatoCmsProvider(
        filter: {
          isHighlighted: { eq: true }
          meta: { status: { eq: "published" } }
        }
      ) {
        edges {
          node {
            callout
            contentNode {
              childMarkdownRemark {
                html
              }
            }
            fullName
            id
            isHighlighted
            meta {
              status
            }
            portrait {
              fluid(
                maxWidth: 800
                imgixParams: { fm: "jpg", auto: "compress" }
              ) {
                ...GatsbyDatoCmsFluid
              }
            }
            roleTitle
            services
            slug
          }
        }
      }
    }
  `)
  return (
    <Slider {...settings}>
      {shuffle(data.allDatoCmsProvider.edges).map(({ node: highlighted }) => (
        <Box key={highlighted.id} sx={{ px: [0, 4, 5], textAlign: "left" }}>
          <Card variant="full" sx={{ bg: "primary" }}>
            <Img
              fluid={highlighted.portrait.fluid}
              alt={`${highlighted.title}'s portrait`}
              sx={{
                objectFit: "cover",
                width: ["full", "full", "1/2"],
                bg: "primary",
              }}
            />
            <Flex
              sx={{
                justifyContent: "center",
                flexDirection: "column",
                flexWrap: "wrap",
                width: ["full", "full", "1/2"],
                p: 4,
              }}
            >
              <Styled.h3 sx={{ color: "white" }}>
                {highlighted.fullName}
              </Styled.h3>
              <Text as="p" sx={{ mb: 3, color: "white" }}>
                {highlighted.callout}
              </Text>
              <Link to={`/about-us/highlighted-provider/${highlighted.slug}`}>
                <Button
                  as="span"
                  variant="inverted"
                  sx={{
                    "&:hover": {
                      color: "primary",
                    },
                  }}
                >
                  Read Provider's Story
                </Button>
              </Link>
            </Flex>
          </Card>
        </Box>
      ))}
    </Slider>
  )
}

export default HighlightedProvider
