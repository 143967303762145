/** @jsx jsx */
import { jsx, Box, Container, Flex, Styled } from "theme-ui"
import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBellOn } from "@fortawesome/pro-regular-svg-icons"

const ComponentName = () => {
  const data = useStaticQuery(graphql`
    {
      allDatoCmsAnnouncement(
        limit: 1
        sort: { fields: meta___publishedAt }
        filter: { meta: { status: { eq: "published" } } }
      ) {
        edges {
          node {
            id
            title
            slug
            meta {
              status
              publishedAt(formatString: "MM/DD/YYYY")
            }
            announcement
            teaserNode {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }
  `)
  return (
    <>
      {data.allDatoCmsAnnouncement.edges.map(({ node }) => (
        <Box key={node.id} sx={{ bg: "children.3", py: 2, width: "full" }}>
          <Container sx={{ px: "1em" }}>
            <Flex
              sx={{
                alignItems: "center",
                flexWrap: "no-wrap",
              }}
            >
              <FontAwesomeIcon
                icon={faBellOn}
                sx={{
                  color: "white",
                  fontSize: 5,
                  mr: 3,
                }}
              />
              <Box sx={{ width: "full" }}>
                <Box
                  sx={{
                    color: "text",
                    a: { color: "text" },
                    p: {
                      float: "left",
                      m: 0,
                    },
                  }}
                  dangerouslySetInnerHTML={{
                    __html: node.teaserNode.childMarkdownRemark.html,
                  }}
                />
                {node.announcement && (
                  <Styled.p as="span" sx={{}}>
                    <Link
                      to={`/announcements/${node.slug}`}
                      sx={{
                        color: "text",
                        "a:link": { color: "text" },
                        float: "left",
                      }}
                    >
                      More details
                    </Link>
                  </Styled.p>
                )}
              </Box>
            </Flex>
          </Container>
        </Box>
      ))}
    </>
  )
}

export default ComponentName
