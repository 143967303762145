/** @jsx jsx */
import { jsx, Box, Styled } from "theme-ui"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

const ProgramsSideNav = () => {
  const data = useStaticQuery(graphql`
    {
      allDatoCmsProgram {
        edges {
          node {
            id
            position
            slug
            title
          }
        }
      }
    }
  `)
  return (
    <Box
      as="aside"
      id="sidebar"
      sx={{
        bg: ["transparent", "transparent", "warmgray"],
        flexGrow: 1,
        flexBasis: "sidebar",
      }}
      data-datocms-noindex
    >
      <Box
        as="ul"
        sx={{
          px: [0, 0, 4],
          py: 4,
          ul: { listStyle: "none", margin: 0, padding: 0 },
          li: {
            borderBottomColor: "gray.5",
            borderBottomStyle: "solid",
            borderBottomWidth: 1,
            py: 1,
          },
          "li a": {
            textDecoration: "none",
          },
          "li a:hover": {
            textDecoration: "underline",
          },
          width: ["full"],
        }}
      >
        <Styled.h2 sx={{ mt: 0 }}>Programs</Styled.h2>
        {data.allDatoCmsProgram.edges.map(({ node: program }) => (
          <li key={program.id} sx={{ listStyle: "none" }}>
            <Link to={`/patients-visitors/programs/${program.slug}`}>
              {program.title}
            </Link>
          </li>
        ))}
      </Box>
    </Box>
  )
}

export default ProgramsSideNav
