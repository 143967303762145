/** @jsx jsx */
import { jsx, Box, Flex } from "theme-ui"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons"

import { PageLabel } from "../elements"

function QuickLink(props) {
  return (
    <Flex
      sx={{
        alignItems: "center",
        flexWrap: "no-wrap",
        justifyContent: "space-between",
        height: "full",
        width: "full",
      }}
      variant="styles.quickLinksAlt"
    >
      <Box as="a" href={props.to} sx={{ height: "full", width: "full" }}>
        <PageLabel
          sx={{
            height: "full",
            bg: "white",
            py: 3,
            borderLeftWidth: "4px",
            borderLeftColor: "children.2",
          }}
        >
          <span>{props.title}</span>
          <FontAwesomeIcon icon={faChevronRight} className="fa-lg" />
        </PageLabel>
      </Box>
    </Flex>
  )
}

export default QuickLink
