/** @jsx jsx */
import { jsx, Box } from "theme-ui"
import { RightArrow } from "./Arrows"

function NextArrow(props) {
  const { className, onClick } = props
  return (
    <Box
      className={className}
      sx={{
        display: "block",
        fontSize: 5,
        opacity: 0.5,
        position: "absolute",
        width: "35px",
        height: "35px",
        px: 2,
        "&:hover": {
          opacity: 1,
        },
      }}
      onClick={onClick}
    >
      <RightArrow sx={{ position: "relative", top: 0 }} />
    </Box>
  )
}

export default NextArrow
