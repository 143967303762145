/** @jsx jsx */
import { jsx, Styled, Text } from "theme-ui"

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from "@reach/accordion"
import "@reach/accordion/styles.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons"

const ProgramsFaqs = ({ faqs }) => {
  return (
    <Accordion
      collapsible
      multiple
      sx={{
        width: ["full"],
      }}
    >
      {faqs?.map(({ ...item }) => (
        <AccordionItem
          key={item?.id}
          sx={{
            borderColor: "white",
            borderBottomStyle: "solid",
            borderBottomWidth: "2px",
            m: 0,
            p: 0,
            width: ["full"],
          }}
        >
          <Styled.h3 sx={{ my: 0 }}>
            <AccordionButton
              sx={{
                alignItems: "center",
                border: "none",
                cursor: "pointer",
                display: "flex",
                fontSize: "inherit",
                fontFamily: "inherit",
                justifyContent: "space-between",
                py: 2,
                px: 3,
                textAlign: "left",
                width: "full",
                '&[aria-expanded="true"]': {
                  svg: {
                    transform: "rotate(180deg)",
                    transitionProperty: "transform",
                    transitionDuration: "0.15s",
                  },
                },
                "&:hover": {
                  backgroundColor: "gray.3",
                  "svg.fa-chevron-down": {
                    transform: "translateY(5px)",
                    transitionProperty: "transform",
                    transitionDuration: "0.15s",
                  },
                },
              }}
            >
              {item?.question}
              <FontAwesomeIcon icon={faChevronDown} />
            </AccordionButton>
          </Styled.h3>

          <AccordionPanel
            sx={{
              bg: "babyblue",
              py: [3],
              px: [2, 3],
            }}
          >
            <Text
              sx={{ width: ["full"] }}
              dangerouslySetInnerHTML={{
                __html: item?.answerNode.childMarkdownRemark?.html,
              }}
            />
          </AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  )
}

export default ProgramsFaqs
