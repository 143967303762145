/** @jsx jsx */
import { jsx, Box, Button, Divider, Flex, Text } from "theme-ui"
import React, { useState } from "react"
import { StaticQuery, graphql } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronSquareDown,
  faChevronSquareUp,
} from "@fortawesome/pro-regular-svg-icons"

const MegaFooter = props => {
  const [isFooterOn, setFooterOn] = useState(false)
  const toggleFooter = () => setFooterOn(!isFooterOn)
  return (
    <StaticQuery
      query={graphql`
        {
          datoCmsWvumfooter {
            id
            section {
              id
              linksNode {
                childMarkdownRemark {
                  html
                }
              }
              section
            }
          }
          allDatoCmsHospital(sort: { fields: position }) {
            edges {
              node {
                hospitalName
                id
                position
                websiteUrl
              }
            }
          }
          allDatoCmsInstitute(sort: { fields: position }) {
            edges {
              node {
                instituteName
                id
                position
                websiteUrl
              }
            }
          }
        }
      `}
      render={data => (
        <>
          <Box px={[0, 0, 0, 4]} sx={{ width: ["full"] }}>
            <Button
              variant="outline"
              id="megamenu"
              onClick={toggleFooter}
              aria-label={`${
                isFooterOn ? "close footer menu" : "open footer menu"
              }`}
              sx={{
                display: "flex",
                verticalAlign: "center",
              }}
            >
              WVU Medicine Network{" "}
              {isFooterOn ? (
                <FontAwesomeIcon
                  icon={faChevronSquareUp}
                  sx={{ fontSize: 2, ml: 2 }}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faChevronSquareDown}
                  sx={{ fontSize: 2, ml: 2 }}
                />
              )}
            </Button>
          </Box>

          <Box
            px={[0, 0, 0, 4]}
            sx={{
              width: ["full"],
              display: isFooterOn ? "block" : "none",
              visibility: isFooterOn ? "visible" : "hidden",
              opacity: isFooterOn ? 1 : 0,
              transition: `opacity 0.25s, visibility 0.25s`,
            }}
          >
            <Divider sx={{ mx: 0 }} />
            <Flex
              mx={[0, 0, 0, -4]}
              sx={{ flexWrap: "wrap", justifyContent: "space-between" }}
            >
              <Box
                px={[0, 0, 2, 4]}
                sx={{ width: ["full", "1/2", "1/4", "1/4"] }}
              >
                {/* Patients */}
                {data.datoCmsWvumfooter.section
                  .map(({ id, linksNode, section }) => (
                    <Box key={id}>
                      <Text
                        sx={{
                          fontSize: 0,
                          fontWeight: "semibold",
                          marginBottom: 2,
                        }}
                      >
                        {section[0]}
                      </Text>
                      <Text
                        sx={{
                          ul: {
                            listStyle: "none",
                            margin: "0 0 2rem 0",
                            padding: 0,
                          },
                          li: {
                            marginBottom: `calc(1.45rem / 2.5)`,
                          },
                          "li a": {
                            color: "text",
                            textDecoration: "none",
                          },
                          "li a:hover": {
                            textDecoration: "underline",
                          },
                          width: ["full"],
                        }}
                        dangerouslySetInnerHTML={{
                          __html: linksNode.childMarkdownRemark.html,
                        }}
                      />
                    </Box>
                  ))
                  .slice(0, 1)}
              </Box>

              <Box
                px={[0, 0, 2, 4]}
                sx={{ width: ["full", "1/2", "1/4", "1/4"] }}
              >
                {/* Medical */}
                {data.datoCmsWvumfooter.section
                  .map(({ id, linksNode, section }) => (
                    <Box key={id}>
                      <Text
                        sx={{
                          fontSize: 0,
                          fontWeight: "semibold",
                          marginBottom: 2,
                        }}
                      >
                        {section[0]}
                      </Text>
                      <Text
                        sx={{
                          ul: {
                            listStyle: "none",
                            margin: "0 0 2rem 0",
                            padding: 0,
                          },
                          li: {
                            marginBottom: `calc(1.45rem / 2.5)`,
                          },
                          "li a": {
                            color: "text",
                            textDecoration: "none",
                          },
                          "li a:hover": {
                            textDecoration: "underline",
                          },
                          width: ["full"],
                        }}
                        dangerouslySetInnerHTML={{
                          __html: linksNode.childMarkdownRemark.html,
                        }}
                      />
                    </Box>
                  ))
                  .slice(1, 2)}
              </Box>

              <Box
                px={[0, 0, 2, 4]}
                sx={{ width: ["full", "1/2", "1/4", "1/4"] }}
              >
                {/* About */}
                {data.datoCmsWvumfooter.section
                  .map(({ id, linksNode, section }) => (
                    <Box key={id}>
                      <Text
                        sx={{
                          fontSize: 0,
                          fontWeight: "semibold",
                          marginBottom: 2,
                        }}
                      >
                        {section[0]}
                      </Text>
                      <Text
                        sx={{
                          ul: {
                            listStyle: "none",
                            margin: "0 0 2rem 0",
                            padding: 0,
                          },
                          li: {
                            marginBottom: `calc(1.45rem / 2.5)`,
                          },
                          "li a": {
                            color: "text",
                            textDecoration: "none",
                          },
                          "li a:hover": {
                            textDecoration: "underline",
                          },
                          width: ["full"],
                        }}
                        dangerouslySetInnerHTML={{
                          __html: linksNode.childMarkdownRemark.html,
                        }}
                      />
                    </Box>
                  ))
                  .slice(3, 4)}
              </Box>

              <Box
                px={[0, 0, 2, 4]}
                sx={{ width: ["full", "1/2", "1/4", "1/4"] }}
              >
                {/* Community */}
                {data.datoCmsWvumfooter.section
                  .map(({ id, linksNode, section }) => (
                    <Box key={id}>
                      <Text
                        sx={{
                          fontSize: 0,
                          fontWeight: "semibold",
                          marginBottom: 2,
                        }}
                      >
                        {section[0]}
                      </Text>
                      <Text
                        sx={{
                          ul: {
                            listStyle: "none",
                            margin: "0 0 2rem 0",
                            padding: 0,
                          },
                          li: {
                            marginBottom: `calc(1.45rem / 2.5)`,
                          },
                          "li a": {
                            color: "text",
                            textDecoration: "none",
                          },
                          "li a:hover": {
                            textDecoration: "underline",
                          },
                          width: ["full"],
                        }}
                        dangerouslySetInnerHTML={{
                          __html: linksNode.childMarkdownRemark.html,
                        }}
                      />
                    </Box>
                  ))
                  .slice(2, 3)}
              </Box>
            </Flex>
          </Box>
        </>
      )}
    ></StaticQuery>
  )
}

export default MegaFooter
