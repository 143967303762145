/** @jsx jsx */
import { jsx, Box, Button, Flex, Styled } from "theme-ui"
import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

const HighlightedServices = () => {
  const data = useStaticQuery(graphql`
    {
      allDatoCmsChildrensService(
        filter: {
          highlighted: { eq: true }
          meta: { status: { eq: "published" } }
        }
        limit: 3
      ) {
        edges {
          node {
            title
            id
            image {
              fluid(
                maxWidth: 800
                imgixParams: { fm: "jpg", auto: "compress" }
              ) {
                ...GatsbyDatoCmsFluid
              }
            }
            meta {
              status
            }
            slug
          }
        }
      }
    }
  `)

  const HighlightedServices = data.allDatoCmsChildrensService.edges.map(
    ({ node: service }) => {
      return service
    }
  )

  return (
    <>
      <Flex
        sx={{
          flexWrap: "wrap",
          mx: [0, 0, -2],
          justifyContent: "center",
        }}
      >
        {data.allDatoCmsChildrensService.edges.map(({ node: service }) => (
          <>
            <Box
              key={service.id}
              sx={{
                px: [0, 0, 2],
                my: [2, 2, 0],
                width: ["full", "full", "1/3"],
              }}
            >
              <Flex
                sx={{
                  bg: "white",
                  boxShadow: "lg",
                  flexDirection: "column",
                  my: 2,
                  height: "full",
                }}
              >
                <Box sx={{ width: "full" }}>
                  {service.image
                    .map(({ fluid }) => (
                      <Box sx={{ width: "full" }}>
                        <Img fluid={fluid} alt={service.title} />
                      </Box>
                    ))
                    .slice(0, 1)}
                </Box>

                <Box
                  sx={{
                    px: [4],
                    pt: 4,
                    width: "full",
                  }}
                >
                  <Styled.h3 sx={{ mb: 2, mt: 1 }}>{service.title}</Styled.h3>
                </Box>

                <Flex
                  sx={{
                    flexDirection: "column",
                    height: "full",
                    justifyContent: "flex-end",
                    px: [4],
                    pb: [3, 4],
                  }}
                >
                  <Link
                    to={`/services/specialty-care/${service.slug}`}
                    aria-label={`Read more about ${service.title}`}
                  >
                    <Button as="span" variant="outline">
                      {`More Info`}
                    </Button>
                  </Link>
                </Flex>
              </Flex>
            </Box>
          </>
        ))}
      </Flex>
    </>
  )
}

export default HighlightedServices
