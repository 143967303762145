/** @jsx jsx */
import { jsx, Box, Container, Styled } from "theme-ui"

import { Hero } from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"
import { ColorBar } from "../../components/elements"

const BlogHero = (props) => {
  return (
    <Hero sx={{postion: 'relative', overflow: 'hidden'}}>
          <Box as="img" src="https://www.datocms-assets.com/23576/1704383854-blog-hero.jpg" sx={{ position: 'absolute', top: 0, bottom: 0, right: 0, left:0, width: "full", height: 'full', objectFit: 'cover'}} />
          
            <Container
              sx={{ position: "relative", px: "1em", py: [5, 6], zIndex: 10 }}
            >
            <Styled.h1 sx={{ display: 'none'}}>Hope and Health</Styled.h1>
            <Box sx={{ width: ['3/4', '60%', '40%'],  }}>
              <Box as="img" src="https://www.datocms-assets.com/23576/1704393786-hope-health-logo.svg" sx={{ position: 'relative', }} />

              <Box as="p" sx={{ fontSize: [1,1,2]}}>Your source to help with your family's health from WVU Medicine Children's</Box>
            
            </Box>
              {props.children}
          </Container>
          <ColorBar sx={{ position: "absolute", bottom: 0, left: 0, zIndex: 10 }} />
        </Hero>
  )
}

export default BlogHero