/** @jsx jsx */
import { jsx, Select } from 'theme-ui'

const SelectDropdownAuthor = ({options, setauthorItem}, props) => {
  return (
    <Select
      defaultValue="Filter by Author"
      onChange={(e) => {
        setauthorItem(e.target.value);
      }}
      sx={{width: 'full'}}
      >
      <option value="Filter by Author">Filter by Author</option>
      {options.map(( {...option}, index ) => (
        <option value={option.title} key={`${option.title}-${index}`}>{ option.title }</option>
      ))}      
    </Select>
  )
}

export default SelectDropdownAuthor