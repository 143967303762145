/** @jsx jsx */
import { jsx, Box } from "theme-ui"

const Overlay = props => {
  return (
    <Box
      {...props}
      sx={{
        background: `linear-gradient(to right, rgba(86, 45, 181, 0.8), rgba(25, 175, 201, 0.8))`,
        position: "absolute",
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        width: "full",
        height: "full",
        zIndex: 1,
      }}
    />
  )
}

Overlay.defaultProps = {
  opacity: "0.5",
}

Overlay.displayName = "Overlay"

export default Overlay
