/** @jsx jsx */
import { jsx, Flex } from "theme-ui"

const PageLabel = props => {
  return (
    <Flex
      sx={{
        alignItems: "center",
        backgroundColor: "white",
        borderColor: "gray.3",
        borderRadius: "default",
        borderStyle: "solid",
        borderWidth: "1px",
        flexWrap: "no-wrap",
        justifyContent: "space-between",
        mb: 3,
        px: 3,
        py: 2,
        "&:hover": {
          "svg.fa-chevron-right": {
            transform: "translateX(5px)",
            transitionProperty: "transform",
            transitionDuration: "0.15s",
          },
        },
      }}
      {...props}
    >
      {props.children}
    </Flex>
  )
}

export default PageLabel
