/** @jsx jsx */
import { jsx, Box, Flex } from "theme-ui"

const LabelIcon = props => {
  return (
    <Flex sx={{ alignItems: "center" }}>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexWrap: "no-wrap",
          backgroundColor: `${props.bgColor}`,
          color: "white",
          minWidth: "48px",
          borderRadius: "full",
          justifyContent: "center",
          height: "48px",
          width: "48px",
        }}
      >
        {props.children}
      </Box>
      <span sx={{ pl: 2 }}>{props.title}</span>
    </Flex>
  )
}

export default LabelIcon
