/** @jsx jsx */
import { jsx, Box, Flex } from "theme-ui"
import { StaticQuery, graphql, Link } from "gatsby"

import { LinkExt } from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"

const FooterNav = props => (
  <StaticQuery
    query={graphql`
      {
        datoCmsServicePage {
          id
          slug
          title
        }
      }
    `}
    render={data => (
      <Box sx={{ width: ["full"] }}>
        <Box px={[0]} sx={{ width: ["full"] }}>
          <Flex
            mx={[0]}
            sx={{ flexWrap: "wrap", justifyContent: "space-between" }}
          >
            <Box
              px={[0, 2, 2, 4]}
              sx={{ width: ["full", "1/2", "1/4", "1/4"] }}
            >
              <p sx={{ marginBottom: 2 }}>
                <strong>About Us</strong>
              </p>
              <ul
                sx={{
                  variant: "styles.footerLinks",
                }}
              >
                <li>
                  <Link to="/about-us#leadership">Leadership</Link>
                </li>
                <li>
                  <Link to="/about-us#highlighted">Highlighted Providers</Link>
                </li>
                <li>
                  <Link to="/about-us/facilities">
                    Facilities and Amenities
                  </Link>
                </li>
                <li>
                  <a href="https://wvumedicine.org/careers">
                    Careers
                  </a>
                </li>
              </ul>

              <p sx={{ marginBottom: 2 }}>
                <strong>Giving</strong>
              </p>
              <ul
                sx={{
                  variant: "styles.footerLinks",
                }}
              >
                <li>
                  <LinkExt href="https://give.wvu.edu/campaign/wvu-medicine-childrens/c430998">Learn About Giving</LinkExt>
                </li>
                <li>
                  <LinkExt href="https://give.wvu.edu/give/430998/#!/donation/checkout">Make a Donation</LinkExt>
                </li>
              </ul>
            </Box>

            {/* Patients & Visitors */}
            <Box
              px={[0, 2, 2, 4]}
              sx={{ width: ["full", "1/2", "1/4", "1/4"] }}
            >
              <p sx={{ marginBottom: 2 }}>
                <strong>Patients & Visitors</strong>
              </p>
              <ul
                sx={{
                  variant: "styles.footerLinks",
                }}
              >
                <li>
                  <Link to="/patients-visitors/your-hospital-stay">
                    Your Hospital Stay
                  </Link>
                </li>
                <li>
                  <Link to="/patients-visitors/visitor-resources">
                    Visitor Resources
                  </Link>
                </li>
                <li>
                  <a href="https://mywvuchart.com/MyChart/">MyWVUChart</a>
                </li>
                <li>
                  <Link to="/patients-visitors/programs">Programs</Link>
                </li>
                <li>
                  <Link to="/blog">Blog</Link>
                </li>
              </ul>
            </Box>

            {/* Services */}
            <Box
              px={[0, 2, 2, 4]}
              sx={{ width: ["full", "1/2", "1/4", "1/4"] }}
            >
              <p sx={{ marginBottom: 2 }}>
                <strong>Services</strong>
              </p>
              <ul
                sx={{
                  variant: "styles.footerLinks",
                }}
              >
                <li>
                  <Link to="/services/specialty-care">Specialty Care</Link>
                </li>
                <li>
                  <Link to="/services/primary-care">Primary Care</Link>
                </li>
                <li>
                  <Link to="/services#womens-care">
                    Affiliated Women's Care
                  </Link>
                </li>
                <li>
                  <LinkExt to="https://wvumedicine.org/wvum-services/urgent-care/">
                    Urgent Care
                  </LinkExt>
                </li>
              </ul>
            </Box>

            <Box
              px={[0, 2, 2, 4]}
              sx={{ width: ["full", "1/2", "1/4", "1/4"] }}
            >
              <p sx={{ marginBottom: 2 }}>
                <strong>For Medical Professionals</strong>
              </p>
              <ul
                sx={{
                  variant: "styles.footerLinks",
                }}
              >
                <li>
                  <a href="https://wvumedicine.org/providerhub/how-to-refer-a-patient/">
                    Refer a Patient
                  </a>
                </li>
                <li>
                  <a href="https://wvumedicine.org/providerhub/transfer-a-patient/">
                    Transfer a Patient
                  </a>
                </li>
                <li>
                  <Link to="/for-medical-professionals#specialists-directory">
                    Specialists Directory
                  </Link>
                </li>
                
              </ul>
            </Box>
          </Flex>
        </Box>
      </Box>
    )}
  ></StaticQuery>
)

export default FooterNav
