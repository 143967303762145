/** @jsx jsx */
import { jsx, Box } from "theme-ui"
import ReactPlayer from "react-player"

function VideoPlayer(props) {
  return (
    <Box sx={{ my: 3, paddingTop: "56.25%", position: "relative" }}>
      <ReactPlayer
        url={props.url}
        controls
        width="100%"
        height="100%"
        style={{ position: "absolute", top: 0, left: 0 }}
      />
    </Box>
  )
}

export default VideoPlayer
