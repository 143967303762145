/** @jsx jsx */
import { jsx, Box, Button, Flex } from "theme-ui"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

const FundraiseActivityCard = () => {
  const data = useStaticQuery(graphql`
    {
      allDatoCmsFundraiseActivity {
        edges {
          node {
            id
            title
            introContent
            slug
            heroImage {
              fluid(
                maxWidth: 800
                imgixParams: { fm: "jpg", auto: "compress" }
              ) {
                ...GatsbyDatoCmsFluid
              }
            }
          }
        }
      }
    }
  `)
  return (
    <Flex sx={{ flexWrap: "wrap", mx: [0, 0, -2], mt: 4, width: "full" }}>
      {data.allDatoCmsFundraiseActivity.edges.map(({ node: activity }) => (
        <Box
          key={activity.id}
          sx={{ px: [0, 0, 2], my: [2], width: ["full", "full", "1/2"] }}
        >
          <Flex
            sx={{
              bg: "white",
              borderRadius: "default",
              boxShadow: "lg",
              flexDirection: "column",
              justifyContent: "space-between",
              my: 2,
              height: "full",
              maxWidth: "full",
            }}
          >
            <Img fluid={activity.heroImage.fluid} sx={{ maxHeight: "337px" }} />

            <Box sx={{ px: 4, py: 4, width: "full" }}>
              <Box as="h3" sx={{ textAlign: "center", fontSize: "4" }}>
                {activity.title}
              </Box>
              <Box as="p" sx={{ textAlign: "center" }}>
                {activity.introContent}
              </Box>
            </Box>

            <Box sx={{ pb: 3, width: "full", textAlign: "center" }}>
              <Link
                to={`/giving/fundraise-your-way/${activity.slug}`}
                sx={{ textDecoration: "none", textAlign: "center" }}
              >
                <Button as="span" variant="outline">
                  Get Started
                </Button>
              </Link>
            </Box>
          </Flex>
        </Box>
      ))}
    </Flex>
  )
}

export default FundraiseActivityCard
