/** @jsx jsx */
import { jsx, Box, Flex, Container, Divider } from "theme-ui"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFacebookF,
  faTwitter,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons"

import Logo from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements/Logo"
import FooterNav from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout/FooterNav"
import MegaFooter from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout/MegaFooter"

import { CmnLogo } from "../../../../components/elements/"
import { LinkExt } from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"

const Footer = ({ facebook, twitter, instagram, youTube, phone, address }) => {
  const d = new Date()
  const n = d.getFullYear()

  return (
    <Box
      as="footer"
      sx={{ bg: "warmgray", fontSize: ["0"] }}
      data-datocms-noindex
    >
      <Container sx={{ px: "1em" }}>
        <Flex
          mx={[0, 0, 0, -4]}
          py={5}
          sx={{
            alignItems: "center",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <Box
            mb={[2, 2, 0]}
            px={[0, 0, 0, 4]}
            sx={{ width: ["1/2", "1/2", "1/4"] }}
          >
            <Logo sx={{ maxWidth: "240px" }} />
          </Box>
          <Box px={[0, 0, 0, 4]} sx={{ width: ["full", "full", "1/3", "1/4"] }}>
            <LinkExt
              to="https://wvumedicine.childrensmiraclenetworkhospitals.org/"
              aria-label="Childrnes Miracle Network"
            >
              <CmnLogo sx={{ maxWidth: "200px", mb: [2, 2, 0] }} />
            </LinkExt>
          </Box>
          <Box px={[0, 0, 0, 4]} sx={{ width: ["full", "full", "1/3", "1/4"] }}>
            <p>
              {address && address}
              <br />
              {phone && phone}
            </p>
          </Box>
          <Box
            px={[0, 0, 0, 4]}
            sx={{ width: ["full", "full", "full", "1/4"] }}
          >
            <ul
              sx={{
                variant: "styles.listReset",
                textAlign: ["left"],
                margin: 0,
                li: {
                  display: "inline",
                  fontSize: "1.5em",
                },
                svg: {
                  color: "text",
                  minWidth: "40px",
                  "&:hover": {
                    color: "children.4",
                  },
                },
              }}
            >
              {facebook && (
                <li>
                  <a href={facebook} aria-label="Facebook">
                    <FontAwesomeIcon icon={faFacebookF} />
                  </a>
                </li>
              )}
              {twitter && (
                <li>
                  <a href={twitter} aria-label="Twitter">
                    <FontAwesomeIcon icon={faTwitter} />
                  </a>
                </li>
              )}
              {instagram && (
                <li>
                  <a href={instagram} aria-label="Instagram">
                    <FontAwesomeIcon icon={faInstagram} />
                  </a>
                </li>
              )}
              {youTube && (
                <li>
                  <a href={youTube} aria-label="YouTube">
                    <FontAwesomeIcon icon={faYoutube} />
                  </a>
                </li>
              )}
            </ul>
          </Box>
          <Box px={[0, 0, 0, 4]} sx={{ width: ["full"] }}>
            <Divider sx={{ mx: 0 }} />
          </Box>

          <FooterNav />

          <MegaFooter />

          <Box px={[0, 0, 0, 4]} sx={{ width: ["full"] }}>
            <Divider sx={{ mx: 0 }} />
            &copy; {n} Copyright - West Virginia University Health System
          </Box>
        </Flex>
      </Container>
    </Box>
  )
}

export default Footer
