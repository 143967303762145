/** @jsx jsx */
import { Link } from "gatsby"
import { jsx, Grid, Flex } from "theme-ui"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons"

import { PageLabel } from "."

const CentersOfExcellenceRelatedPrograms = ({ programs }) => {
  return (
    <Grid
      gap={3}
      columns={[1, 2]}
      mt={[4]}
      sx={{ gridAutoRows: "1fr", justifyContent: "center" }}
    >
      {programs?.map(({ ...program }) => (
        <Flex
          key={program?.id}
          sx={{
            alignItems: "center",
            flexWrap: "no-wrap",
            justifyContent: "space-between",
            height: "full",
            width: "full",
          }}
          variant="styles.quickLinksAlt"
        >
          <Link
            to={`/services/programs/${program?.slug}`}
            sx={{ height: "full", width: "full" }}
          >
            <PageLabel
              sx={{
                height: "full",
                bg: "white",
                py: 3,
                borderLeftWidth: "4px",
                borderLeftColor: "primary",
              }}
            >
              <span>{program?.title}</span>
              <FontAwesomeIcon icon={faChevronRight} className="fa-lg" />
            </PageLabel>
          </Link>
        </Flex>
      ))}
    </Grid>
  )
}

export default CentersOfExcellenceRelatedPrograms
