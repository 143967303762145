/** @jsx jsx */
import { jsx, Box, Flex, Grid } from "theme-ui"
import { StaticQuery, graphql, Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons"

import { PageLabel, SectionHeading } from "."

const CentersOfExcellenceLabels = props => (
  <StaticQuery
    query={graphql`
      {
        allDatoCmsCenterOfExcellence(
          filter: { meta: { status: { eq: "published" } } }
          sort: { order: ASC, fields: position }
        ) {
          edges {
            node {
              slug
              title
              id
              meta {
                status
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Flex
        id="centers-of-excellence"
        sx={{ flexWrap: "wrap", width: "full", mb: 36 }}
      >
        <Box sx={{ width: ["full"], textAlign: "center" }}>
          <SectionHeading>Centers Of Excellence</SectionHeading>
        </Box>
        <Box sx={{ width: ["full"] }}>
          <Grid
            gap={3}
            columns={[1, 2, 3]}
            sx={{ gridAutoRows: "1fr", justifyContent: "center" }}
          >
            {data.allDatoCmsCenterOfExcellence.edges.map(({ node: center }) => (
              <Flex
                key={center.id}
                sx={{
                  alignItems: "center",
                  flexWrap: "no-wrap",
                  justifyContent: "space-between",
                  height: "full",
                  width: "full",
                }}
                variant="styles.quickLinksAlt"
              >
                <Link
                  to={`/about-us/centers-of-excellence/${center.slug}`}
                  sx={{ height: "full", width: "full" }}
                >
                  <PageLabel
                    sx={{
                      height: "full",
                      bg: "white",
                      py: 3,
                      borderLeftWidth: "4px",
                      borderLeftColor: "primary",
                    }}
                  >
                    <span>{center.title}</span>
                    <FontAwesomeIcon icon={faChevronRight} className="fa-lg" />
                  </PageLabel>
                </Link>
              </Flex>
            ))}
          </Grid>
        </Box>
      </Flex>
    )}
  ></StaticQuery>
)

export default CentersOfExcellenceLabels
