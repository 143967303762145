/** @jsx jsx */
import { jsx, Box } from "theme-ui"
import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs"
import { groupBy, kebabCase, uniqueId } from "lodash"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons"

const ServicesDirectory = () => {
  const data = useStaticQuery(graphql`
    {
      allDatoCmsChildrensService(
        sort: { fields: title, order: ASC }
        filter: { meta: { status: { eq: "published" } } }
      ) {
        edges {
          node {
            id
            title
            slug

            meta {
              status
            }
          }
        }
      }
    }
  `)

  const servicesLetters = data.allDatoCmsChildrensService.edges.map(
    ({ node: service }) => {
      let initial = service.title.charAt(0)
      return { service, initial }
    }
  )
  const servicesGroup = groupBy(servicesLetters, "initial")
  const servicesEntries = Object.entries(servicesGroup)

  return (
    <Box>
      <Tabs sx={{ flexWrap: "wrap", width: "full" }}>
        <TabList
          sx={{
            display: "flex",
            flexWrap: "wrap",
            width: "full",
            backgroundColor: "white",
            "button:hover, [data-selected]": {
              backgroundColor: "primary",
              color: "white",
              cursor: "pointer",
            },
          }}
        >
          {servicesEntries.map(letter => (
            <Tab
              key={`initial_${letter[0]}`}
              sx={{
                borderWidth: "1px",
                borderColor: "gray.3",
                borderStyle: "solid",
                borderRadius: "default",
                color: "primary",
                fontSize: 2,
                px: 3,
                py: 2,
                m: 1,
                width: "48px",
              }}
            >
              {letter[0]}
            </Tab>
          ))}
        </TabList>

        <TabPanels sx={{ mt: 4 }}>
          {servicesEntries.map(services => (
            <TabPanel key={`${uniqueId("service_group_id_")}`}>
              <Box as="ul" variant="styles.listReset" sx={{ pl: 3 }}>
                {services[1].map(({ service }) => (
                  <React.Fragment key={`${uniqueId("service_id_")}`}>
                    <Box
                      as="li"
                      sx={{
                        display: "inline-block",
                        p: 2,
                        width: ["full", "full", "1/2"],
                      }}
                    >
                      <Link
                        to={`/services/specialty-care/${kebabCase(
                          service.slug
                        )}`}
                        sx={{
                          textDecoration: "none",
                          textTransform: "uppercase",
                          color: "text",
                          "&:hover": {
                            color: "primary",
                            textDecoration: "underline",
                            "svg.fa-arrow-right": {
                              transform: "translateX(5px)",
                              transitionProperty: "transform",
                              transitionDuration: "0.15s",
                              color: "children.4",
                            },
                          },
                        }}
                      >
                        {service.title} <FontAwesomeIcon icon={faArrowRight} />
                      </Link>
                    </Box>
                  </React.Fragment>
                ))}
              </Box>
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Box>
  )
}

export default ServicesDirectory
