/** @jsx jsx */
import { jsx, Box, Flex, Grid } from "theme-ui"
import { StaticQuery, graphql, Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons"

import { PageLabel, SectionHeading } from "../elements"

const Units = props => (
  <StaticQuery
    query={graphql`
      {
        allDatoCmsUnit(
          filter: { meta: { status: { eq: "published" } } }
          sort: { order: ASC, fields: position }
        ) {
          edges {
            node {
              slug
              title
              id
              meta {
                status
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Flex id="units" sx={{ flexWrap: "wrap", width: "full" }}>
        <Box sx={{ width: ["full"], textAlign: "center" }}>
          <SectionHeading>Units</SectionHeading>
        </Box>
        <Box sx={{ width: ["full"] }}>
          <Grid gap={3} columns={[1, 2, 3]} sx={{ gridAutoRows: "1fr" }}>
            {data.allDatoCmsUnit.edges.map(({ node: unit }) => (
              <Flex
                key={unit.id}
                sx={{
                  alignItems: "center",
                  flexWrap: "no-wrap",
                  justifyContent: "space-between",
                  height: "full",
                  width: "full",
                }}
                variant="styles.quickLinksAlt"
              >
                <Link
                  to={`/about-us/units/${unit.slug}`}
                  sx={{ height: "full", width: "full" }}
                >
                  <PageLabel
                    sx={{
                      height: "full",
                      bg: "white",
                      py: 3,
                      borderLeftWidth: "4px",
                      borderLeftColor: "secondary",
                    }}
                  >
                    <span>{unit.title}</span>
                    <FontAwesomeIcon icon={faChevronRight} className="fa-lg" />
                  </PageLabel>
                </Link>
              </Flex>
            ))}
          </Grid>
        </Box>
      </Flex>
    )}
  ></StaticQuery>
)

export default Units
